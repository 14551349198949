import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { callMSGraphPhoto, callMSGraphUser } from "../api/graph";
import { User } from "@microsoft/microsoft-graph-types";
import { postUserPwToken } from "./apiRequets";
import { TSURL_TOKEN } from "../config";



export async function getToken(user: string, pw: string, secret: string): Promise<string> {
    return new Promise(async (resolve) => {
        let token = await getAccessToken(`${TSURL_TOKEN}`,user,pw,secret);
        resolve(token);
    });
}


export async function getAccessToken(url: string, user: string, pw: string, secret: string): Promise<any> {
    //const accountData = AccountData.GetAccountData();
    /*
    if (!accountData.instance) {
        console.log("Username or password is empty");
        return "";
    }*/
    //console.log(`${accountData.instance}`);
    let token = await postUserPwToken(url,user,pw, secret);
    return token;
}

export class AccountData {
    private static instance: IPublicClientApplication;
    private static account: AccountInfo;
    private static user: User;
    private static userImage: Blob;

    // Marked private to enforce singleton pattern.
    private constructor() {}

    public static GetAccountData() {
        return { instance: this.instance, account: this.account };
    }

    public static async setAccountData(instance: IPublicClientApplication, account: AccountInfo): Promise<any> {
        this.instance = instance;
        this.account = account;
        this.user = await callMSGraphUser();
        this.userImage = await callMSGraphPhoto().catch(console.log);
        return Promise.resolve();
    }

    public static getUser(): User {
        return this.user;
    }

    public static getUSerImage() {
        return this.userImage;
    }
}
