import React, { useState } from "react";
import { IQuoteSummary } from "../../../interfaces/IQuoteSummery";

import "./style.scss";
import { VscOpenPreview, VscSave, VscFilePdf, VscLiveShare } from "react-icons/vsc";

import { Link } from "react-router-dom";
import Modal from "../../molecules/Modal/Modal";
import GeneratePdfForm from "../../GeneratePdfForm/GeneratePdfForm";


function EditBar(props: { offer: IQuoteSummary; saveDocument: Function; saved: boolean }) {
    const [showPdfModal, setShowPdfModal] = useState<boolean>(false);


    const currentUrl = window.location.href;
    const url = `${currentUrl}/${props.offer.id}/${props.offer.version}`; 

    const handleCopyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(url);
            console.log("Text copied to clipboard:", url);
        } catch (error) {
            console.error("Error copying text to clipboard:", error);
        }
        };

    function closeAdd() {
        setShowPdfModal(false);
    }

    function openModal() {
        setShowPdfModal(true);
    }

    return (
        <div className={`quote-edit-document__edit-bar ${!props.saved && "active"}`}>
            <Modal show={showPdfModal} close={closeAdd} title="generate PDF quote">
                <GeneratePdfForm offer={props.offer} closeModal={closeAdd}></GeneratePdfForm>
            </Modal>

            <div onClick={() => props.saveDocument()} title="save quote document">
                <VscSave className={`edit-bar__icon-pdf ${!props.saved && "active"}`}></VscSave>
            </div>

            <div onClick={() => openModal()} title="show timesafe bookings">
                <VscFilePdf className="edit-bar__icon-pdf"></VscFilePdf>
            </div>
            <Link
                to={{
                    pathname: "timesafeoffer",
                    state: props.offer
                }}>
                <VscOpenPreview className="edit-bar__icon-pdf"></VscOpenPreview>
            </Link>

            <div onClick={() =>handleCopyToClipboard()} title="Copy direct URL to clipboard">
                <VscLiveShare className={"edit-bar__icon-pdf"}></VscLiveShare>
            </div>
        </div>
    );
}

export default EditBar;

/*
<Link
to={{
    pathname: "timesafeoffer",
    state: props.offer
}}>
Offerten abgleich
</Link>
*/
