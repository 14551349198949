import React, { useEffect, useState } from "react";

import API from "../../api/apiHandler";
import moment from "moment";
import "moment/locale/de";
import "./style.scss";
import { EMTPY_PROJECT, IProject } from "../../interfaces/IProject";
import Select from "react-select";
import { IIdString } from "../../interfaces/IIdString";
import { ITsProjectPhases } from "../../interfaces/ITsProjectPhases";
import { IOrderLabel } from "../../interfaces/IOrderLabel";
import { IEmployee } from "../../interfaces/IEmployee";
import { AccountData } from "../../Utils/Auth";
import { EMPTY_ORGANIZATION} from "../../interfaces/IOrganization";
import { ICustomer } from "../../interfaces/ICustomer";

function OrderingsContainer() {
    moment.locale("de");

    const [organization, setOrganization] = useState<[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<{}>({});

    const [project, setProject] = useState<[]>([]);
    const [selectedProject, setSelectedProject] = useState<{}>({});

    const [materialType, setMaterialType] = useState<[]>([]);
    const [selectedMaterialType, setSelectedMaterialType] = useState<{}>({});

    const [projPhase, setProjPhase] = useState<[]>([]);
    const [selectedProjPhase, setSelectedProjPhase] = useState<{}>({});

    const [distributor, setDistributor] = useState<[]>([]);
    const [selectedDistributor, setSelectedDistributor] = useState<{}>({});

    const [transport, setTransport] = useState<[]>([]);
    const [selectedTransport, setSelectedTransport] = useState<{}>({});

    const [currencies, setCurrencies] = useState<[]>([]);
    const [selectedCurrencies, setSelectedCurrencies] = useState<{}>({});

    const [paymentMethodes, setPaymentMethodes] = useState<[]>([]);
    const [selectedPaymentMethodes, setSelectedPaymentMethodes] = useState<{}>({});

    const [customers, setCustomers] = useState<ICustomer[]>([]);

    const [orderId, setOrderId] = useState<string>("");
    const [costAmount, setCostAmount] = useState<string>("");
    const [materialText, setMaterialText] = useState<string>("");
    const [distributorOrderId, setDistributorOrderId] = useState<string>("");
    const [costAdd, setCostAdd] = useState<string>("");

    const [showOrderOrderId, SetShowOrderOrderId] = useState<string>("");
    const [showOrderCustomer, SetShowOrderCustomer] = useState<string>("");
    const [showOrderProject, SetShowOrderProject] = useState<string>("");
    const [showOrderProjectNr, SetShowOrderProjectNr] = useState<string>("");
    const [showOrderCost, SetShowOrderCost] = useState<string>("");
    const [showOrderAddCost, SetShowOrderAddCost] = useState<string>("");
    const [showOrderBooking, SetShowOrderBooking] = useState<string>("");
    const [showOrderPhase, SetShowOrderPhase] = useState<string>("");
    const [showOrderMaterialType, SetShowOrderMaterialType] = useState<string>("");
    const [showOrderMaterialText, SetShowOrderMaterialText] = useState<string>("");
    const [showOrderPayType, SetShowOrderPayType] = useState<string>("");

    console.log("TEST ORDERING CONTAINER rendered");

    async function getOrganizations() {
        let organizations = await API.TimeSafeFunctions.getAllTimeSafeOrganizations();
        setOrganization(organizations);/*
        let organization : [IOrganization];
        customer.forEach(e => {
            organization.pop(e)
        });*/
    }

    async function getCustomerData(){
        let customer = await API.Customer.getAllCustomerData().catch((error: string) => {
            console.error(error);
        });
        setCustomers(customer);
    }

    async function getProjectPhases(projectId: string | "") {
        let ph = (await API.TimeSafeProjectPhases.getAllTimeSafeProjPhases(projectId)) as [ITsProjectPhases];
        let phases: [{ value: string; label: string }] = [{ value: "0", label: "" }];

        ph.forEach((p) => {
            if (p.AktivityId == 0) {
                phases.push({ value: p.PhaseTitle, label: p.PhaseTitle });
            }
        });

        setProjPhase(phases as any);
    }

    async function getCurrencies() {
        let currencies = await API.OrderingCurrencies.getAllCurrencies();
        setCurrencies(currencies);
    }

    async function getMaterialTypes() {
        let materialType = await API.TimeSafeMaterialTypes.getAllTimeSafeMaterialTypes();
        setMaterialType(materialType);
    }

    async function getPaymentMethodes() {
        let paymentMethodes = await API.OrderingPaymentMethodes.getAllPaymentMethodes();
        setPaymentMethodes(paymentMethodes);
    }

    async function getDistributors() {
        let distributor = await API.OrderingDistributors.getAllDistributors();
        setDistributor(distributor);
    }

    async function getOrderId() {
        let orderId = (await API.OrderingOrderId.getNewOrderId()) as IIdString;
        setOrderId(orderId.String as any);
    }

    async function getOrder(id: string) {
        let orderL = (await API.OrderingOrder.getOrder(id)) as [IOrderLabel];
        if (orderL != null) {
            if (orderL[0] != null) {
                let order = orderL[0];

                try {
                    SetShowOrderOrderId(order.OrderId);
                    SetShowOrderCustomer(order.Organization.Name);
                    SetShowOrderProject(order.Project.ProjectName);
                    SetShowOrderProjectNr(order.Project.ProjectNo);
                    SetShowOrderCost(order.Cost.toString());
                    SetShowOrderAddCost(order.CostAdd.toString());
                    SetShowOrderBooking(order.Booking.toString());
                    SetShowOrderPhase(order.Phase);
                    SetShowOrderMaterialType(order.MaterialType);
                    SetShowOrderMaterialText(order.MaterialText);
                    SetShowOrderPayType(order.PaymentMethod);
                } catch {
                    SetShowOrderOrderId("");
                    clearShowOrder();
                    SetShowOrderCustomer("not valide order-number!");
                }
            }
        }
    }

    async function clearShowOrder() {
        SetShowOrderCustomer("");
        SetShowOrderProject("");
        SetShowOrderProjectNr("");
        SetShowOrderCost("");
        SetShowOrderAddCost("");
        SetShowOrderBooking("");
        SetShowOrderPhase("");
        SetShowOrderMaterialType("");
        SetShowOrderMaterialText("");
        SetShowOrderPayType("");
    }

    /*
    async function clearAllCreate(){
        let emptyLabelValue : {label:string,value:string} = {label:"",value:""};
        setProject(emptyLabelValue as any);
    }*/

    async function saveOrder(order: IOrderLabel) {
        if (order) {
            let response = await API.OrderingOrder.saveOrder(order);
            console.log(response);
        }
    }

    async function getTransportCompanies() {
        let transport = await API.OrderingTransportComp.getAllTransportCompanies();
        setTransport(transport);
    }

    async function getProjects(orgId: string | null) {
        let proj = (await API.Projects.getProjects(null, null, orgId, null)) as [IProject];
        //let projects : [{value: string, label: string}] ;
        let projects: [{ value: string; label: string }] = [{ value: "0", label: "" }];

        proj.forEach((p) => {
            projects.push({ value: `${p.ProjectId}`, label: p.ProjectName });
        });
        setProject(projects as any);
    }

    console.log(project);

    useEffect(() => {
        getCustomerData();
        console.log(customers);
        getOrganizations();

        getDistributors();
        getTransportCompanies();
        getCurrencies();
        getMaterialTypes();
        SetShowOrderOrderId("");
    }, []);

    let proList = project.map((item: { value: string; label: string }) => {
        return {
            value: item.value,
            label: item.label
        };
    });

    let materialTypeList = materialType.map((item: IIdString) => {
        return {
            value: item.String,
            label: item.String
        };
    });

    let orgList = organization.map((item: IIdString) => {
        return {
            value: item.String,
            label: item.String
        };
    });

    let phaseList = projPhase.map((item: { value: string; label: string }) => {
        return {
            value: item.value,
            label: item.label
        };
    });

    let currencyList = currencies.map((item: IIdString) => {
        return {
            value: item.String,
            label: item.String
        };
    });

    let transportList = transport.map((item: IIdString) => {
        return {
            value: item.String,
            label: item.String
        };
    });

    let distributorList = distributor.map((item: IIdString) => {
        return {
            value: item.String,
            label: item.String
        };
    });

    let paymentMethodeList = paymentMethodes.map((item: IIdString) => {
        return {
            value: item.String,
            label: item.String
        };
    });

    async function handleOnChangeOrganization(event: any) {
        if (event.value === "") return;
        if (event.value === null) return;

        let orgString = event.value;
        setSelectedOrganization({ label: orgString, value: orgString });

        let organizId = 0;
        let org = (await API.TimeSafeFunctions.getAllTimeSafeOrganizations()) as [IIdString];
        org.forEach((o) => {
            if (o.String === orgString) {
                organizId = o.Id;
            }
        });
        console.log(organizId);
        getProjects(organizId.toString());
    }

    function handleOnChangeShowOrderId(e: any) {
        let str = e as string;
        if (str === "") return;
        SetShowOrderOrderId(str);
        if (str.length == 9) {
            getOrder(str);
        } else {
            clearShowOrder();
        }
    }

    async function storeOrder() {
        let emp: IEmployee = {
            FirstName: AccountData.getUser().givenName || "",
            LastName: AccountData.getUser().surname || "",
            TimeSafeId: 0
        };

        let organiz = EMPTY_ORGANIZATION;
        let org = (await API.TimeSafeFunctions.getAllTimeSafeOrganizations()) as [IIdString];
        org.forEach((o) => {
            let selOrg = (selectedOrganization as { value: string; label: string }).label;
            if (o.String === selOrg) {
                organiz.OrganizationId = o.Id;
                organiz.Name = o.String;
            }
            console.log(o + selOrg);
        });
        console.log(org);

        let projList = (await API.Projects.getProjects(null, null, null, null)) as [
            { DateTimeEnd: string; DateTimeStart: string; Id: number; ProjectName: string ; ProjectNo: string }
        ];

        let selProject = EMTPY_PROJECT;
        projList.forEach(p => {
            let selProj = selectedProject as {value: string, label: string};

          if(p.ProjectName === selProj.label){
            selProject.ProjectId = p.Id;
            selProject.ProjectName = p.ProjectName;
            selProject.ProjectNo = p.ProjectNo;
          }
        });

        let orderLabel: IOrderLabel = {
            OrderId: orderId,
            TransportCompany: (selectedTransport as { value: string; label: string }).label,
            Currency: (selectedCurrencies as { value: string; label: string }).label,
            MaterialText: materialText,
            MaterialType: (selectedMaterialType as { value: string; label: string }).label,
            Booked: new Date(2000, 1, 1),
            Cost: Number(costAmount),
            CreationDate: new Date(),
            Booking: false,
            Employee: emp,
            DistributorOrderId: distributorOrderId,
            FileReferences: [""],
            FileStorage: "",
            CostAdd: Number(costAdd),
            Organization: organiz,
            Payed: new Date(2000, 1, 1),
            PaymentMethod: (selectedPaymentMethodes as { value: string; label: string }).label,
            Phase: (selectedProjPhase as { value: string; label: string }).label,
            Project: selProject,
            WorkGroup: ""
        };

        saveOrder(orderLabel);
        console.log(orderLabel);
        //TODO: OrderLabel
    }

    async function handleOnChangeProject(event: any) {
        if (event.value === "") return;
        if (event.value === null) return;
        let selProjectString = event.label;
        setSelectedProject({ label: selProjectString, value: selProjectString });

        let projList = (await API.Projects.getProjects(null, null, null, null)) as [
            { DateTimeEnd: string; DateTimeStart: string; Id: number; ProjectName: string ; ProjectNo: string }
        ];
        let projId = "";
        projList.forEach((c) => {
            if (c.ProjectName === selProjectString) {
                projId = `${c.Id}`;
            }
        });
        getProjectPhases(projId);
        getOrderId();
        getPaymentMethodes();
        setCostAdd("20");

        //Set default currency and transport
        let cList = (await API.OrderingCurrencies.getAllCurrencies()) as [IIdString];
        cList.forEach((c) => {
            if (c.Id === 0) {
                setSelectedCurrencies({ value: c.String, label: c.String });
            }
        });

        let tList = (await API.OrderingTransportComp.getAllTransportCompanies()) as [IIdString];
        tList.forEach((c) => {
            if (c.Id === 0) {
                setSelectedTransport({ value: c.String, label: c.String });
            }
        });
    }

    return (
        <div className="ordering--container">
            <table className="tableContainter" width="900px">
                <th>
                    <tr>
                        <div className="input">
                            <input autoComplete="off" type="text" name="gender" value={orderId} />
                            <label htmlFor="male">Order-ID</label>
                        </div>
                    </tr>

                    <div id="OrderingBlock">
                        <label id="OrderingBlockTitel">PROJECT DETAILS</label>
                        <tr>
                            <div id="OrderingTableBox">
                                <label>ORGANIZATION:</label>
                                <Select
                                    className="select"
                                    options={orgList}
                                    value={selectedOrganization}
                                    onChange={(event: any) =>
                                        handleOnChangeOrganization({ label: event.label, value: event.value })
                                    }
                                />
                            </div>
                        </tr>

                        <tr>
                            <div id="OrderingTableBox">
                                <label>PROJECT:</label>
                                <Select
                                    className="select"
                                    options={proList}
                                    value={selectedProject}
                                    onChange={(event: any) =>
                                        handleOnChangeProject({ label: event.label, value: event.value })
                                    }
                                />
                            </div>
                        </tr>

                        <tr>
                            <div id="OrderingTableBox">
                                <label>PHASES:</label>
                                <Select
                                    className="select"
                                    options={phaseList}
                                    value={selectedProjPhase}
                                    onChange={(event: any) =>
                                        setSelectedProjPhase({ label: event.label, value: event.value })
                                    }
                                />
                            </div>
                        </tr>
                    </div>

                    <div id="OrderingBlock">
                        <label id="OrderingBlockTitel">MATERIAL OPTIONS</label>
                        <tr>
                            <div id="OrderingTableBox">
                                <label id="labelText">MATERIAL TYPE:</label>

                                <Select
                                    className="select"
                                    options={materialTypeList}
                                    value={selectedMaterialType}
                                    onChange={(event: any) =>
                                        setSelectedMaterialType({ label: event.value, value: event.value })
                                    }
                                />
                            </div>
                            <tr>
                                <div id="OrderingTableBox">
                                    <label id="labelText">MATERIAL TEXT:</label>
                                    <div className="input">
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            name="gender"
                                            value={materialText}
                                            onChange={(e) => setMaterialText(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </tr>
                        </tr>
                    </div>

                    <div id="OrderingBlock">
                        <label id="OrderingBlockTitel">DISTRIBUTOR</label>
                        <tr>
                            <div id="OrderingTableBox">
                                <label>DISTRIBUTOR:</label>
                                <Select
                                    className="select"
                                    options={distributorList}
                                    value={selectedDistributor}
                                    onChange={(event: any) =>
                                        setSelectedDistributor({ label: event.value, value: event.value })
                                    }
                                />
                            </div>
                        </tr>
                        <tr>
                            <div id="OrderingTableBox">
                                <label>Distributor Order Id</label>
                                <div className="input">
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        name="gender"
                                        value={distributorOrderId}
                                        onChange={(e) => setDistributorOrderId(e.target.value)}
                                    />
                                </div>
                            </div>
                        </tr>
                    </div>

                    <div id="OrderingBlock">
                        <label id="OrderingBlockTitel">ORDER OPTIONS</label>

                        <tr>
                            <div id="OrderingTableBox">
                                <label>TRANSPORT:</label>
                                <Select
                                    className="select"
                                    options={transportList}
                                    value={selectedTransport}
                                    onChange={(event: any) =>
                                        setSelectedTransport({ label: event.value, value: event.value })
                                    }
                                />
                            </div>
                        </tr>

                        <tr>
                            <div id="OrderingTableBox">
                                <label>PAYMENT METHODE:</label>
                                <Select
                                    className="select"
                                    options={paymentMethodeList}
                                    value={selectedPaymentMethodes}
                                    onChange={(event: any) =>
                                        setSelectedPaymentMethodes({ label: event.value, value: event.value })
                                    }
                                />
                            </div>
                        </tr>

                        <tr>
                            <div id="OrderingTableBox">
                                <label>CURRENCY:</label>
                                <Select
                                    className="select"
                                    options={currencyList}
                                    value={selectedCurrencies}
                                    onChange={(event: any) =>
                                        setSelectedCurrencies({ label: event.value, value: event.value })
                                    }
                                />
                            </div>
                        </tr>
                        <tr>
                            <table>
                                <th>
                                    <tr>
                                        <div id="OrderingTableBox">
                                            <label>Cost</label>
                                            <div className="input">
                                                <input
                                                    autoComplete="off"
                                                    type="text"
                                                    name="gender"
                                                    value={costAmount}
                                                    onChange={(e) => setCostAmount(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </tr>
                                </th>

                                <th>
                                    <div id="OrderingTableBox">
                                        <label>COST ADD (%):</label>
                                        <div className="input">
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                name="gender"
                                                value={costAdd}
                                                onChange={(e) => setCostAmount(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </th>
                            </table>
                        </tr>
                    </div>

                    <tr>
                        <button id="button" onClick={storeOrder}>
                            Create Order
                        </button>
                    </tr>
                </th>
                <th></th>
                <th>
                    <tr></tr>
                    <div id="OrderingLabelBlock">
                        <label id="OrderingBlockTitel">LOAD ORDER</label>

                        <div id="OrderingTableBox">
                            <div className="input">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="gender"
                                    value={showOrderOrderId}
                                    onChange={(e) => handleOnChangeShowOrderId(e.target.value)}
                                />
                                <label htmlFor="male">Order ID</label>
                            </div>
                        </div>

                        <div id="OrderingTableBox">
                            <div className="input">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="gender"
                                    value={showOrderCustomer}
                                    onChange={(e) => SetShowOrderCustomer(e.target.value)}
                                />
                                <label htmlFor="male">Customer</label>
                            </div>
                        </div>

                        <div id="OrderingTableBox">
                            <div className="input">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="gender"
                                    value={showOrderProject}
                                    onChange={(e) => SetShowOrderProject(e.target.value)}
                                />
                                <label htmlFor="male">Project</label>
                            </div>
                        </div>

                        <div id="OrderingTableBox">
                            <div className="input">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="gender"
                                    value={showOrderProjectNr}
                                    onChange={(e) => SetShowOrderProjectNr(e.target.value)}
                                />
                                <label htmlFor="male">Project Nr.</label>
                            </div>
                        </div>

                        <div id="OrderingTableBox">
                            <div className="input">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="gender"
                                    value={showOrderCost}
                                    onChange={(e) => SetShowOrderCost(e.target.value)}
                                />
                                <label htmlFor="male">Cost</label>
                            </div>
                        </div>

                        <div id="OrderingTableBox">
                            <div className="input">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="gender"
                                    value={showOrderAddCost}
                                    onChange={(e) => SetShowOrderAddCost(e.target.value)}
                                />
                                <label htmlFor="male">Cost Add</label>
                            </div>
                        </div>

                        <div id="OrderingTableBox">
                            <div className="input">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="gender"
                                    value={showOrderBooking}
                                    onChange={(e) => SetShowOrderBooking(e.target.value)}
                                />
                                <label htmlFor="male">Booking</label>
                            </div>
                        </div>

                        <div id="OrderingTableBox">
                            <div className="input">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="gender"
                                    value={showOrderPhase}
                                    onChange={(e) => SetShowOrderPhase(e.target.value)}
                                />
                                <label htmlFor="male">ProjectPhase</label>
                            </div>
                        </div>

                        <div id="OrderingTableBox">
                            <div className="input">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="gender"
                                    value={showOrderMaterialType}
                                    onChange={(e) => SetShowOrderMaterialType(e.target.value)}
                                />
                                <label htmlFor="male">MaterialType</label>
                            </div>
                        </div>

                        <div id="OrderingTableBox">
                            <div className="input">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="gender"
                                    value={showOrderMaterialText}
                                    onChange={(e) => SetShowOrderMaterialText(e.target.value)}
                                />
                                <label htmlFor="male">MaterialText</label>
                            </div>
                        </div>

                        <div id="OrderingTableBox">
                            <div className="input">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="gender"
                                    value={showOrderPayType}
                                    onChange={(e) => SetShowOrderPayType(e.target.value)}
                                />
                                <label htmlFor="male">Pay Type</label>
                            </div>
                        </div>
                    </div>
                </th>
            </table>
        </div>
    );
}

export default OrderingsContainer;
