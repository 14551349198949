import React from "react";
import { ITask } from "../../interfaces/ITask";
import { ITimeSafeEntry } from "../../interfaces/ITimeSafeEntry";

import { isNumber, secondsToHour } from "../../Utils/Utilities";
import "./timesafeOffer/task.scss";

function Entry(props: { entryTimeSafe: ITimeSafeEntry; index: number; entry: ITask }) {
    let incurredTime = secondsToHour(Number.parseInt(props.entryTimeSafe.DurationIncurred));
    let offeredTime = secondsToHour(props.entry.Duration);
    let openTime = offeredTime-incurredTime;

    const forecast = ((incurredTime / props.entryTimeSafe.Completition) * 100).toFixed(2);
    const forecastPercent = (100 / secondsToHour(props.entry.Duration)) * incurredTime;
    const estimatedPercent = (Number(forecast) / secondsToHour(props.entry.Duration)) * 100;

    return (
        <div className="timesafeoffer-phase--task">
            <div className="task--tag task--item">{props.entryTimeSafe.Id}</div>
            <div className="task--title task--item">{props.entryTimeSafe.Title}</div>
            <div className="task--target task--item">{offeredTime.toFixed(2)}</div>
            <div className="task--actual task--item">{incurredTime.toFixed(2)}</div>
            <div className="task--actual task open--item">{openTime.toFixed(2)}</div>
            <div className="task--braked"></div>
            <div
                className="task--progress task--item"
                style={{
                    background: `linear-gradient(to right, #079600 , #00000000 ${props.entryTimeSafe.Completition}%)`
                }}>
                {props.entryTimeSafe.Completition}
            </div>
            <div className="task--forecast task--item">{isNumber(forecast)}</div>

            <div
                className="task--forecast-percent task--item"
                style={{
                    background: `linear-gradient(to right, ${
                        forecastPercent >= 100 ? "#ff0000" : "#fff700"
                    }, #00000000 ${estimatedPercent}%)`
                }}>
                {isNumber(estimatedPercent).toFixed(2)}
            </div>
            <div className="task--open">{isNumber((Number(forecast) - incurredTime).toFixed(2))}</div>
        </div>
    );
}

export default Entry;
