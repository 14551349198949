import React from "react";
import QuoteEditDocument from "./QuoteEditDocument/QuoteEditDocument";

function QuoteContainer(props: any) {
    return (
        <>
            <div className="container">
                <QuoteEditDocument id={props.data.id} version={props.data.version} />
            </div>
        </>
    );
}

export default QuoteContainer;
