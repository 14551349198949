import React, { useState } from "react";
import { ICustomer } from "../../../../interfaces/ICustomer";
import { IProject } from "../../../../interfaces/IProject";
import ProjectListItem from "../ProjectList/ProjectListItem";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

function CustomerListItem(props: { customer: ICustomer; expand: boolean }) {
    let [expand, setExpand] = useState(props.expand);

    let projects = props.customer.Projects || [];
    let ProjectList = projects.map((project: IProject) => (
        <ProjectListItem project={project} expand={projects.length <= 3} key={project.ProjectId}></ProjectListItem>
    ));

    return (
        <div className="customer-item">
            <div
                className={`customer-item__item-header ${props.expand || expand ? "expanded" : ""}`}
                onClick={() => setExpand(!expand)}>
                <div className="item-header__title">{props.customer.Organization.Name}</div>
                <div className="item-header__number">Customer No: {props.customer.CustomerNo}</div>
                <div className="item-header__count"></div>
                <div className="item-header__expand">
                    {!props.expand && projects.length > 0 && !expand && <MdExpandMore />}
                    {!props.expand && projects.length > 0 && expand && <MdExpandLess />}
                </div>
            </div>
            {(expand || props.expand) && <div className="customer-item__item-body">{ProjectList}</div>}
        </div>
    );
}

export default CustomerListItem;
