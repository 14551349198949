import React from "react";
import { Link } from "react-router-dom";
import { IFilter } from "../../interfaces/IFilter";

function Filter(props: { filter: IFilter; addFilterFn: Function; active?: boolean }) {
    return (
        <>
            {props.filter.type !== "GROUPBY" && (
                <div className="filter" onClick={() => props.addFilterFn(props.filter)}>
                    {props.filter.title}{" "}
                </div>
            )}
            {props.filter.type === "GROUPBY" && (
                <Link
                    className={`filter group ${props.active ? "active" : ""}`}
                    to={{
                        pathname: "/filter",
                        state: {
                            filter: props.filter.fn()
                        }
                    }}>
                    {props.filter.title}{" "}
                </Link>
            )}
        </>
    );
}

export default Filter;
