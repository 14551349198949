import { useAccount, useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import API from "../../api/apiHandler";
import { dateStringToInputDate, secondsToHour } from "../../Utils/Utilities";
import moment from "moment";
import "moment/locale/de";
import { IProject } from "../../interfaces/IProject";
import { ITimeSafeV1Entry } from "../../interfaces/ITimeSafeV1Entry";
import "./style.scss";

let ENTRY = styled.div`
    text-align: left;
`;

let CONTENT = styled.div`
    padding: 20px;
    text-align: left;
    display: flex;
    flex-direction: row;
    flex: 1;
`;
let TABLE = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    flex: 1;
`;

function ThanosV1Container() {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [projects, setProjects] = useState<IProject[]>([]);
    moment.now();
    let d1 = moment().subtract(7, "d").format("YYYY-MM-DD");
    let d2 = moment().format("YYYY-MM-DD");
    console.log(d1, d2);
    const [dateFrom, setDateFrom] = useState<string>(d1);
    const [dateTo, setDateTo] = useState<string>(d2);
    const [keys, setKeys] = useState<string[]>([]);
    const [selectedProjectId, setSelectedProjectId] = useState<number>(-1);
    const [entrys, setEntrys] = useState<ITimeSafeV1Entry[]>([]);

    async function getProjects() {
        if (account) {
            let loadProjects = await API.Projects.getProjects(null, null, null, null) as IProject[];    
            let projects : IProject[];
            projects = loadProjects.sort((a,b)=> a.ProjectName.localeCompare(b.ProjectName));
            setProjects(projects);
        }
    }

    async function getEntrySummary(projectId: number) {
        let keys = await API.EntrySummary.getEntrySummary(
            dateStringToInputDate(dateFrom),
            dateStringToInputDate(dateTo),
            projectId
        );
        setKeys(keys);
    }
    async function getProjectEntrys(projectId: number) {
        if (account) {
            let tasks = await API.ProjectEntrys.getProjectEntrys(
                instance,
                account,
                dateStringToInputDate(dateFrom),
                dateStringToInputDate(dateTo),
                projectId
            );
            setEntrys(tasks);
        }
    }

    useEffect(() => {
        getProjects();
    }, []);

    useEffect(() => {
        getEntrySummary(selectedProjectId);
        getProjectEntrys(selectedProjectId);
    }, [selectedProjectId, dateFrom, dateTo]);

    let projectList = projects.map((projects: any) => (
        <option key={projects.Id} value={projects.Id}>
            {projects.ProjectName}
        </option>
    ));

    let keyless = entrys.filter((entry: ITimeSafeV1Entry) => entry.Id === "");

    let keylesslist = keyless.map((key, index) => (
        <div key={index}>
            <strong>{key.Employee} </strong> doesn't like Tags{" "}
            <strong>{secondsToHour(key.Duration).toFixed(2)}h</strong>
            <pre>{key.Entry}</pre>
        </div>
    ));

    function handleOnChangeDateFrom(event: any) {
        if (event.target.value === "") return;
        let inDate = event.target.value;
        console.log("datelog");
        console.log(inDate);
        setDateFrom(inDate);
    }

    function handleOnChangeDateTo(event: any) {
        if (event.target.value === "") return;
        let inDate = event.target.value;
        console.log("datelog");
        console.log(inDate);
        setDateTo(inDate);
    }

    function handleSelectChange(event: any) {
        setSelectedProjectId(event.target.value);
    }

    let list = Array.from(Object.entries(keys));
    let keyList = list.map((key: any) => (
        <ENTRY key={key[0]}>
            {key[0]} &nbsp;&nbsp;&nbsp;&nbsp; {secondsToHour(key[1]).toFixed(2)}h
        </ENTRY>
    ));

    return (
        <div className="thanosV1--container">
            <select onChange={handleSelectChange}>{projectList}</select>
            FROM:
            <input type="date" onChange={handleOnChangeDateFrom} value={dateStringToInputDate(dateFrom)}></input>
            TO:
            <input type="date" onChange={handleOnChangeDateTo} value={dateStringToInputDate(dateTo)}></input>
            <CONTENT>
                <TABLE>{keyList}</TABLE>
                <TABLE>{keylesslist}</TABLE>
            </CONTENT>
        </div>
    );
}

export default ThanosV1Container;
