export interface IEmployee {
    TimeSafeId: number;
    FirstName: string;
    LastName: string;
}

export const EMPTY_EMPLOYEE: IEmployee = {
    FirstName: "",
    LastName: "",
    TimeSafeId: 0
};
