import React, { useState } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import "./style.scss";
import { NavigationItem } from "./navigationItem";

function Navigation(props: any) {
    const [max, setMax] = useState<boolean>(false);

    return (
        <nav className={max ? "nav max" : "nav"}>
            <AuthenticatedTemplate>
                <input type="checkbox" id="menuToggler" />
                <div className="head">
                    <div className="nav-title">MENU</div>
                    <label htmlFor="menuToggler"></label>
                </div>
                <ul className={`nav-list ${!max && " min"}`}>
                    <div className="nav-item icon cancel" onClick={() => setMax(!max)}></div>
                    <NavigationItem title="Home" referTo="/" filter="home"></NavigationItem>
                    <NavigationItem title="Quotes" referTo="filter" filter="quotes"></NavigationItem>
                    <NavigationItem title="Timesafe" referTo="timesafe" filter="timesafe"></NavigationItem>
                    <NavigationItem title="Projects" referTo="filter" filter="projects"></NavigationItem>
                    <NavigationItem title="Customers" referTo="filter" filter="customers"></NavigationItem>
                    <NavigationItem title="Ordering" referTo="ordering" filter="ordering"></NavigationItem>
                    {props.admin && (
                        <NavigationItem title="ThanosV1" referTo="v1thanos" className="old"></NavigationItem>
                    )}
                </ul>
            </AuthenticatedTemplate>
        </nav>
    );
}

export default Navigation;
