import { API_ADMIN_URL } from "../config";
import { IQuoteSummary } from "../interfaces/IQuoteSummery";
import { getToken } from "../Utils/Auth";
import { getWithToken } from "./apiRequets";

const URL_TIMESAFE = "TimeSafeOffer";

//#######################################################
//TIMESAFE OFFER
//#######################################################

export async function getTimesafeOfferByOffer(
    from: string | null,
    to: string | null,
    quote: IQuoteSummary
): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        const token = await getToken();
        let parameter = {};

        if (from !== null && to !== null) {
            parameter = { dtfrom: from, dtto: to, id: quote.id, version: quote.version };
        } else {
            parameter = { id: quote.id, version: quote.version };
        }

        const response = await getWithToken(token, API_ADMIN_URL + URL_TIMESAFE, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

export async function getProjectsEntry(
    accessToken: string,
    _url: string,
    from: string | null,
    to: string | null,
    id: number
): Promise<any> {
    return new Promise(async (resolve, reject) => {
        const url: URL = new URL(_url);

        const params: any =
            from !== null && to !== null ? { dtfrom: from, dtto: to, projectid: id } : { projectid: id };
        url.search = new URLSearchParams(params).toString();

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);
        headers.append("Content-Type", "application/json");

        const options = {
            method: "GET",
            headers: headers
        };

        fetch(url.href, options)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export default { getTimesafeOfferByOffer };
