import { API_ADMIN_URL } from "../config";
import { getToken } from "../Utils/Auth";
import { getWithToken } from "./apiRequets";

const URL_SUMMARY = "entrysummary";

export async function getEntrySummary(from: string | null, to: string | null, id: number): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        const token = await getToken();
        let parameter = {};
        if (from !== null && to !== null) {
            parameter = { dtfrom: from, dtto: to, projectid: id };
        }

        const response = await getWithToken(token, API_ADMIN_URL + URL_SUMMARY, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

export default { getEntrySummary };
