import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ICustomer } from "../../../interfaces/ICustomer";
import { IProject } from "../../../interfaces/IProject";
import DashboardContainer from "../Container";

function ContainerProject(props: { classes: string; customers: ICustomer[] }) {
    const [filter, setFilter] = useState<string>("");

    const projects: IProject[] = [];
    props.customers.forEach((customer: ICustomer) =>
        customer.Projects.forEach((project: IProject) => projects.push(project))
    );

    const stringFilter = (project: IProject) => project.ProjectName.toLowerCase().includes(filter.toLowerCase());

    const projectList = projects.filter(stringFilter).map((project) => (
        <Link
            className={`db-customer-list-item ${filter} `}
            to={{
                pathname: "filter",
                state: {
                    filter: "projects",
                    content: project.ProjectName
                }
            }}>
            <div className="nav-item-text">
                <span className="text-customer-number">{project.ProjectNo}</span>
                <span>{project.ProjectName}</span>
            </div>
        </Link>
    ));

    return (
        <DashboardContainer headerName="PROJECTS" className={`ds-projects ${props.classes}`}>
            <div className="customers__input">
                <input
                    placeholder="Search for projects"
                    className="input"
                    type="text"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}></input>
            </div>

            {projectList.length === 0 ||
                (filter === "" && (
                    <div className="numOfCustomers">
                        <span className="number">{projectList.length}</span>
                        <span className="label">Projects</span>
                    </div>
                ))}
            {projectList.length > 0 && filter !== "" && <div className="db-customer-list">{projectList}</div>}
        </DashboardContainer>
    );
}

export default ContainerProject;
