import { IFilter } from "../../../interfaces/IFilter";
import { IFilterGroup } from "../../../interfaces/IFilterGroup";
import { IFilterMainGroup } from "../../../interfaces/IFilterMainGroup";
import { QuoteState } from "../../../interfaces/IQuote";
import { IQuoteSummary } from "../../../interfaces/IQuoteSummery";
import { FILTER_GROUP_BY } from "./GroupByFilter";

//*********************************************************************/
/*
 * BOOLEAN FILTER QUOTE
 */

const CUSTOMER_FILTER_STATE_OPEN: IFilter = {
    id: "QUOTE_STATE_OPEN",
    title: "OPEN",
    type: "BOOLEAN",
    fn: (quote: IQuoteSummary) => quote.documentState === QuoteState.OPEN
};

const CUSTOMER_FILTER_STATE_READY: IFilter = {
    id: "QUOTE_STATE_READY",
    title: "READY",
    type: "BOOLEAN",
    fn: (quote: IQuoteSummary) => quote.documentState === QuoteState.OPEN
};

//*********************************************************************/
/*
 * Gruppen
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let FILTER_GROUP_QUOTE_BOOLEAN: IFilterGroup = {
    filter: [CUSTOMER_FILTER_STATE_OPEN, CUSTOMER_FILTER_STATE_READY],
    conjunction: "AND",
    type: "BOOLEAN",
    name: "States"
};

//*********************************************************************/
/*
 * Main Gruppe
 */
export let FILTER_GROUP_CUSTOMER: IFilterMainGroup = {
    filterGroups: [FILTER_GROUP_BY]
};

export let FILTER_GROUP_EMPTY: IFilterMainGroup = {
    filterGroups: [FILTER_GROUP_BY]
};
