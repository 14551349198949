import { IQuote } from "./IQuote";
import { IQuotePreview } from "./IQuotePreview";

export interface IProject {
    ProjectId: number;
    ProjectName: string;
    ProjectNo: string;
    Organization: number;
    Quotes: IQuote[];
    QuotesPreview: IQuotePreview[];
}

export const EMTPY_PROJECT : IProject = {
    ProjectId: 0,
    ProjectName: "",
    ProjectNo: "",
    Organization: 0,
    Quotes: [],
    QuotesPreview: []
};
