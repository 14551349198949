import React from "react";
import { Link } from "react-router-dom";

type NavigationItemProps = {
    referTo: string;
    title: string;
    filter?: string;
    className?: string;
};

export function NavigationItem({ referTo, title, filter, className }: NavigationItemProps) {
    return (
        <Link
            className={`nav-item icon ${filter} ${className}`}
            to={{
                pathname: referTo,
                state: {
                    filter: filter
                }
            }}>
            <div className="nav-item-text">{title}</div>
        </Link>
    );
}
