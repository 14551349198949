import React from "react";
import { IFilter } from "../../interfaces/IFilter";

function SelectedFilter(props: { filter: IFilter; removeFilterFn: Function }) {
    return (
        <div className="filter">
            <div className="filter__title">{props.filter.title} </div>
            <div className="filter__icon" onClick={() => props.removeFilterFn(props.filter)}></div>
        </div>
    );
}

export default SelectedFilter;
