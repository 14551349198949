import React, { useEffect, useState } from "react";

import API from "../../api/apiHandler";
import { IProjectPhaseTimeSafeOffer } from "../../interfaces/IProjectPhase";
import { ITimeSafeOffer } from "../../interfaces/ITimeSafeOffer";
import Phase from "./Phase";
import "./style.scss";

import "./timesafeOffer/timesafeoffer.scss";
import { IQuoteSummary } from "../../interfaces/IQuoteSummery";
import { Utilities } from "../../Core/Utilities";
import LoadingContainer from "../molecules/LoadingContainer/LoadingContainer";
function TimeSafeOfferList(props: { quote: IQuoteSummary }) {
    const [offer, setOffer] = useState<ITimeSafeOffer>();

    const [loading, setLoading] = useState<boolean>(true);
    function handleTimeSafeOfferResponse(pOffer: ITimeSafeOffer) {
        setOffer(pOffer);
    }

    useEffect(() => {
        async function getData() {
            let timesafeoffer = await API.TimeSafeOffer.getTimesafeOfferByOffer(null, null, props.quote).catch(
                Utilities.printError
            );

            handleTimeSafeOfferResponse(timesafeoffer);
            setLoading(false);
        }
        getData();
    }, []);

    let timeSafePhasesList = offer?.TimeSafePhases.map((timesafephase: IProjectPhaseTimeSafeOffer, index: number) => (
        <Phase index={index} timesafephase={timesafephase} phase={offer.Phases[index]} key={timesafephase.Id}></Phase>
    ));

    return (
        <>
            {loading && <LoadingContainer />}
            {!loading && (
                <div className="container timesafeoffer--container">
                    <div className="timesafeoffer-container--header">{offer?.Titel}</div>
                    <div className="timesafeoffer-container--content">{timeSafePhasesList}</div>
                </div>
            )}
        </>
    );
}

export default TimeSafeOfferList;
