import React, { useEffect, useState } from "react";
import FilterList from "./Lists/FilterList";
import FilterNavigation from "./FilterNavigation";
import { IFilter } from "../../interfaces/IFilter";
import { FILTER_GROUP_BY } from "./Filters/GroupByFilter";
import Modal from "../molecules/Modal/Modal";
import NewQuoteForm from "../NewQuoteForm/NewQuoteForm";

function FilterContainer(props: any) {
    const [filters, setFilter] = useState<Map<string, IFilter>>(new Map());
    const [showAddQuoteForm, setShowAddQuoteForm] = useState<boolean>(false);

    function closeAdd() {
        setShowAddQuoteForm(false);
    }

    function toggleAdd() {
        setShowAddQuoteForm(!showAddQuoteForm);
    }

    const addFilter = (filter: IFilter) => {
        setFilter((prev) => new Map([...prev, [filter.id, filter]]));
    };
    const removeFilter = (filter: IFilter) => {
        setFilter((prev) => {
            const newFilters = new Map(prev);
            newFilters.delete(filter.id);
            return newFilters;
        });
    };

    useEffect(() => {
        FILTER_GROUP_BY.filter.forEach((filter) => {
            if (filter.fn() === props.linkState.filter) {
                addFilter(filter);
            } else {
                removeFilter(filter);
            }
        });
    }, [props.linkState.filter]);

    return (
        <div className="container container__filter">
            <div className="filter__titlebar">{props.linkState.filter}</div>

            <div className="filter__filter-main">
                <Modal show={showAddQuoteForm} close={closeAdd} title="New Quote">
                    <NewQuoteForm customers={props.customers} closeModal={closeAdd}></NewQuoteForm>
                </Modal>
                <FilterNavigation
                    showAddQuoteForm={toggleAdd}
                    addFilterFn={addFilter}
                    selectedFilters={filters}
                    filter={props.linkState.filter}></FilterNavigation>
                <FilterList
                    customers={props.customers}
                    linkstate={props.linkState}
                    selectedFilters={filters}
                    removeFilterFn={removeFilter}
                />
            </div>
        </div>
    );
}

export default FilterContainer;
