import { IFilter } from "../../../interfaces/IFilter";
import { IFilterGroup } from "../../../interfaces/IFilterGroup";
import { IFilterMainGroup } from "../../../interfaces/IFilterMainGroup";
import { QuoteState } from "../../../interfaces/IQuote";
import { IQuoteSummary } from "../../../interfaces/IQuoteSummery";
import { FILTER_GROUP_BY } from "./GroupByFilter";

//*********************************************************************/
/*
 * BOOLEAN FILTER QUOTE
 */
export const QUOTE_FILTER_STATE_OPEN: IFilter = {
    id: "QUOTE_STATE_OPEN",
    title: "OPEN",
    type: "BOOLEAN",
    fn: (quote: IQuoteSummary) => quote.documentState === QuoteState.OPEN
};

export const QUOTE_FILTER_STATE_READY: IFilter = {
    id: "QUOTE_STATE_READY",
    title: "READY",
    type: "BOOLEAN",
    fn: (quote: IQuoteSummary) => quote.documentState === QuoteState.READY
};

export const QUOTE_FILTER_STATE_ARCHIVED: IFilter = {
    id: "QUOTE_FILTER_STATE_ARCHIVED",
    title: "SHOW ARCHIVED",
    type: "BOOLEAN",
    fn: (quote: IQuoteSummary) => quote.documentState === QuoteState.ARCHIVED
};

export const QUOTE_FILTER_STATE_SENT: IFilter = {
    id: "QUOTE_STATE_SENT",
    title: "SENT",
    type: "BOOLEAN",
    fn: (quote: IQuoteSummary) => quote.documentState === QuoteState.SENT
};

export const QUOTE_FILTER_STATE_WORK: IFilter = {
    id: "QUOTE_STATE_WORK",
    title: "WORK",
    type: "BOOLEAN",
    fn: (quote: IQuoteSummary) => quote.documentState === QuoteState.WORK
};

export const QUOTE_FILTER_STATE_DONE: IFilter = {
    id: "QUOTE_STATE_DONE",
    title: "DONE",
    type: "BOOLEAN",
    fn: (quote: IQuoteSummary) => quote.documentState === QuoteState.DONE
};

export const QUOTE_FILTER_STATE_REJECTED: IFilter = {
    id: "QUOTE_STATE_REJECTED",
    title: "REJECTED",
    type: "BOOLEAN",
    fn: (quote: IQuoteSummary) => quote.documentState === QuoteState.REJECTED
};

//*********************************************************************/
/*
 * GROUPS QUOTE
 */

//BOOLEAN
let FILTER_GROUP_QUOTE_BOOLEAN: IFilterGroup = {
    filter: [QUOTE_FILTER_STATE_OPEN, QUOTE_FILTER_STATE_READY, QUOTE_FILTER_STATE_ARCHIVED, QUOTE_FILTER_STATE_SENT, QUOTE_FILTER_STATE_WORK, QUOTE_FILTER_STATE_DONE, QUOTE_FILTER_STATE_REJECTED],
    conjunction: "AND",
    type: "BOOLEAN",
    name: "States"
};

//*********************************************************************/
/*
 * MAIN GROUP
 */
export let FILTER_GROUP_QUOTE: IFilterMainGroup = {
    filterGroups: [FILTER_GROUP_BY, FILTER_GROUP_QUOTE_BOOLEAN]
};
