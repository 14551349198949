import React, { useEffect, useState } from "react";
import API from "../../api/apiHandler";
import { ICustomer } from "../../interfaces/ICustomer";
import { IProject } from "../../interfaces/IProject";
import { IQuotePreview } from "../../interfaces/IQuotePreview";
import { FormState } from "./NewQuoteForm";

function Step2(props: {
    selectedProject: IProject;
    setSelectedProject: Function;
    selectedCustomer: ICustomer;
    selectedQuote: IQuotePreview;
    setSelectedQuote: Function;
}) {
    const [filterList, setFilterdList] = useState<IProject[]>([]);

    useEffect(() => {
        const getData = async () => {
            let versions = await API.ID.GetQuoteId();
            props.setSelectedQuote({ ...props.selectedQuote, QuoteId: versions.Id });
        };
        getData();
    }, []);

    function autocomplete(e: any) {
        let tempFilterList = props.selectedCustomer.Projects.filter((project) =>
            project.ProjectName.toLowerCase().includes(e.target.value.toLowerCase())
        );
        props.setSelectedProject({ ...props.selectedProject, ProjectName: e.target.value });
        setFilterdList(tempFilterList);
    }

    function selectItem(e: any) {
        //GET correct customer
        let project = props.selectedCustomer.Projects.find(
            (project: IProject) => project.ProjectName === e.target.innerHTML
        );

        if (project) {
            props.setSelectedProject(project);
            setFilterdList([]);
        }
    }

    let filterdList = filterList.map((item) => (
        <li onClick={(e) => selectItem(e)}>
            <div>{item.ProjectName}</div>
            <div>Quotes: {item.QuotesPreview.length}</div>
        </li>
    ));

    return (
        <FormState>
            <div className="form row title">Projectdetails</div>
            <div className="form row">
                <div className="input">
                    <input
                        autoComplete="off"
                        type="text"
                        name="gender"
                        value={props.selectedProject?.ProjectName}
                        onChange={(e) => autocomplete(e)}
                    />
                    <label htmlFor="male">Projectname</label>
                    <ul className={`filterList ${filterdList.length > 0 ? "show" : ""}`}>{filterdList}</ul>
                </div>
            </div>
            <div className="form row">
                <div className="input">
                    <input
                        type="text"
                        name="gender"
                        value={props.selectedProject?.ProjectId}
                        onChange={(e) =>
                            props.setSelectedProject({ ...props.selectedProject, ProjectId: e.target.value })
                        }
                    />
                    <label htmlFor="male">Project Id</label>
                </div>
                <div className="input">
                    <input
                        type="text"
                        name="gender"
                        value={props.selectedProject?.ProjectNo}
                        onChange={(e) =>
                            props.setSelectedProject({ ...props.selectedProject, ProjectNo: e.target.value })
                        }
                    />
                    <label htmlFor="male">Project No</label>
                </div>
            </div>
            <div className="form row title">Quotedetails</div>
            <div className="form row">
                <div className="input">
                    <input
                        autoComplete="off"
                        type="text"
                        name="gender"
                        value={props.selectedQuote.QuoteTitel}
                        onChange={(e) => props.setSelectedQuote({ ...props.selectedQuote, QuoteTitel: e.target.value })}
                    />
                    <label htmlFor="male">Quotename</label>
                </div>
                <div className="input">
                    <input
                        disabled={true}
                        autoComplete="off"
                        type="text"
                        name="gender"
                        value={props.selectedQuote.QuoteId}
                    />
                    <label htmlFor="male">ID</label>
                </div>
            </div>
        </FormState>
    );
}

export default Step2;
