import { API_ADMIN_URL } from "../config";
import { getToken } from "../Utils/Auth";
import { getWithToken } from "./apiRequets";

const URL_OFFER_ID = "CreateId";

function GetPhaseId(quoteId: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        const token = await getToken();
        const parameter = { idtype: "2", quoteid: quoteId, taskid: "" };
        const response = await getWithToken(token, API_ADMIN_URL + URL_OFFER_ID, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

function GetTaskId(quoteId: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        const token = await getToken();
        const parameter = { idtype: "3", quoteid: quoteId, taskid: "" };
        const response = await getWithToken(token, API_ADMIN_URL + URL_OFFER_ID, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

function GetQuoteId(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        const token = await getToken();
        const parameter = { idtype: "1" };
        const response = await getWithToken(token, API_ADMIN_URL + URL_OFFER_ID, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

export function GetTimeSafeTag(quoteId: string, taskId: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        const token = await getToken();
        // eslint-disable-next-line quotes
        const parameter = { idtype: "4", quoteid: quoteId, taskid: taskId };
        const response = await getWithToken(token, API_ADMIN_URL + URL_OFFER_ID, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

export default { GetTaskId, GetPhaseId, GetQuoteId, GetTimeSafeTag };
