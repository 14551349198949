import { API_ORDERING_URL } from "../config";
import { IOrderLabel } from "../interfaces/IOrderLabel";
import { getToken } from "../Utils/Auth";
import { getWithToken, postWithToken } from "./apiRequets";

const ORDER = "Order";

async function getOrder(id: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
        const token = await getToken();
        const parameter = { id: id};
        const response = await getWithToken(token, API_ORDERING_URL + ORDER, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

export async function saveOrder(order : IOrderLabel): Promise<any> {
    return new Promise(async (resolve, reject) => {
        const token = await getToken();
        let parameter = { id: order.OrderId };
        const response = await postWithToken(token, API_ORDERING_URL + ORDER, parameter, order).catch((error) =>
            reject(error)
        );
        if (response.ok) {
            resolve(await (<string>response.json()));
        } else {
            reject(response.statusText);
        }
    });
}

export default {getOrder, saveOrder};