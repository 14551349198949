import React, { useState } from "react";
import { IProject } from "../../../../interfaces/IProject";
import { IQuotePreview } from "../../../../interfaces/IQuotePreview";
import QuoteListItem from "../QuoteList/QuoteListItem";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

function ProjectListItem(props: { project: IProject; expand: boolean }) {
    let [expand, setExpand] = useState(props.expand);

    let quotes: IQuotePreview[] = props.project.QuotesPreview || [];
    let QuoteList = quotes.map((quote: IQuotePreview) => (
        <QuoteListItem quote={quote} key={quote.QuoteId}></QuoteListItem>
    ));

    return (
        <div className="project-item">
            <div className="project-item__item-header" onClick={() => setExpand(!expand)}>
                <div className="item-header__title">{props.project.ProjectName}</div>

                <div className="item-header__count"></div>
                <div className="item-header__expand">
                    {console.log(props.expand)}
                    {!props.expand && QuoteList.length > 0 && !expand && <MdExpandMore />}
                    {!props.expand && QuoteList.length > 0 && expand && <MdExpandLess />}
                </div>
            </div>
            {expand && <div className="project-item__item-body">{QuoteList}</div>}
        </div>
    );
}

export default ProjectListItem;
