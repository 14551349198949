import "./style.scss";
function QuoteEditPhaseHeader() {
    return (
        <div className="phase--task-list-header">
            <div className="task-list-header__position">Position</div>
            <div className="task-list-header__workgroup">Workgroup</div>

            <div className="task-list-header__count">Quantity</div>
            <div className="task-list-header__unit">Unit</div>

            <div className="task-list-header__rate">Rate</div>
            <div className="task-list-header__duration">Total</div>
        </div>
    );
}

export default QuoteEditPhaseHeader;
