import { API_TIMESAFE_URL } from "../config";
import { getToken } from "../Utils/Auth";
import { getWithToken } from "./apiRequets";

const URL_TSPHASES = "TimesafeProjectPhases";

async function getAllTimeSafeProjPhases(projectid: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
        const token = await getToken();
        const parameter = { projectid};
        const response = await getWithToken(token, API_TIMESAFE_URL + URL_TSPHASES, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

export default { getAllTimeSafeProjPhases };
