import { API_ORDERING_URL } from "../config";
import { getToken } from "../Utils/Auth";
import { getWithToken } from "./apiRequets";

const ORDERID = "OrderId";

async function getNewOrderId(): Promise<any> {
    return new Promise(async (resolve, reject) => {
        const token = await getToken();
        const parameter = {};
        const response = await getWithToken(token, API_ORDERING_URL + ORDERID, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

export default {getNewOrderId};