import React, { ReactNode } from "react";

function DashboardContainer(props: { headerName: string; children: ReactNode; className: string }) {
    return (
        <>
            <div className={`dashboard__container ${props.className}`}>
                <div className="container__header">{props.headerName}</div>
                <div className="container__body">{props.children}</div>
            </div>
        </>
    );
}

export default DashboardContainer;
