import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";

import API from "../../api/apiHandler";
import { ICustomer } from "../../interfaces/ICustomer";
import { EMPTY_EMPLOYEE } from "../../interfaces/IEmployee";
import { IHistoryInfo } from "../../interfaces/IHistoryInfo";
import { IProject } from "../../interfaces/IProject";
import { EMPTY_QUOTE_OBJECT, IQuote } from "../../interfaces/IQuote";
import { IQuotePreview } from "../../interfaces/IQuotePreview";
import { IQuoteSummary } from "../../interfaces/IQuoteSummery";
import { AccountData } from "../../Utils/Auth";
import { DataManager } from "../../Utils/DataManager";
import { DataManagerKeys } from "../../Utils/DataManagerKeys";
import { FormState } from "./NewQuoteForm";

function Step3(props: {
    selectedProject: IProject;
    selectedCustomer: ICustomer;
    selectedQuote: IQuotePreview;
    closeModal: Function;
}) {
    let autoTag =
        props.selectedCustomer.Organization.Name.substr(0, 2) + props.selectedProject.ProjectName.substr(0, 2);
    const history = useHistory();
    const [tag, setTag] = useState<string>(autoTag.toUpperCase());
    const [reviewer, setReviewer] = useState<{ lastname: string; firstname: string }>({ firstname: "", lastname: "" });
    const reviewers: string[] = DataManager.GetValue(DataManagerKeys.SYSTEM_REVIEWERS);

    //TODO: refactor (sort when reading)
    let sortReviewerNames : string[];   
    sortReviewerNames= reviewers.sort((a,b)=> a.localeCompare(b));
    const reviewerList = sortReviewerNames.map((reviewer: string) => {
        return { label: reviewer, value: reviewer };
    });
    //--

    async function generateOffer() {
        /* convert datetime TODO: refactoring roman*/
        let dtl = new Date().toLocaleString().split(".");
        for (let i = 0; i < 3; i++) {
            if (dtl[i].length < 2) {
                dtl[i] = `0${dtl[i]}`;
            }
        }
        let date = `${dtl[0]}.${dtl[1]}.${dtl[2]}`;

        const createdInfo: IHistoryInfo = {
            DateTime: date,
            Employee: {
                ...EMPTY_EMPLOYEE,
                FirstName: AccountData.getUser().givenName || "",
                LastName: AccountData.getUser().surname || ""
            },
            HistoryVersion: 0,
            Id: 0
        };

        const reviewerInfo: IHistoryInfo = {
            DateTime: date,
            Employee: {
                ...EMPTY_EMPLOYEE,
                FirstName: reviewer.firstname || "",
                LastName: reviewer.lastname || ""
            },
            HistoryVersion: 0,
            Id: 0
        };

        let newOfferObject: IQuote = { ...EMPTY_QUOTE_OBJECT, Created: createdInfo, Reviewed: [reviewerInfo] };
        console.log(newOfferObject);
        let offer: IQuoteSummary = {
            OfferObject: newOfferObject,
            Organization: props.selectedCustomer.Organization,
            Project: props.selectedProject,
            documentState: 0,
            id: props.selectedQuote.QuoteId.toString(),
            info: "",
            name: props.selectedQuote.QuoteTitel,
            type: "Database",
            version: "1",
            tagForm: tag
        };
        await saveOffer(offer);
        history.push({
            pathname: "/offer",
            state: { id: props.selectedQuote.QuoteId.toString(), version: "1" }
        });
    }

    async function saveOffer(offer: IQuoteSummary) {
        if (offer) {
            let response = await API.Quote.SaveQuote(offer);
            console.log(response);
            props.closeModal();
        }
    }

    const handleNewReviewer = (event: any) => {
        const names = event.value.split(" ");
        setReviewer({ firstname: names[0], lastname: names[1] });
    };

    return (
        <FormState>
            <div className="form row title">Review NewQuote</div>
            <div className="form row">
                <div className="title">Customername</div>
                <div>{props.selectedCustomer.Organization.Name}</div>
                <div className="title">Customer No</div>
                <div>{props.selectedCustomer.Organization.ClientNo}</div>
            </div>
            <div className="form row">
                <div className="title">Projectname</div>
                <div>{props.selectedProject.ProjectName}</div>
                <div className="title">Project No</div>
                <div>{props.selectedProject.ProjectNo}</div>
            </div>
            <div className="form row">
                <div className="title">Quotename</div>
                <div>{props.selectedQuote.QuoteTitel}</div>
                <div className="title">Project No</div>
                <div>{props.selectedQuote.QuoteId}</div>
            </div>
            <div className="form row">
                <div className="title">Creator</div>
                <div>{AccountData.getUser().displayName}</div>
                <div className="title">Reviewer</div>
                <Select options={reviewerList} onChange={(event) => handleNewReviewer(event)}></Select>
            </div>
            <div className="form row">
                <div className="input">
                    <input
                        autoComplete="off"
                        type="text"
                        name="gender"
                        value={tag}
                        onChange={(e) => setTag(e.target.value)}
                    />
                    <label htmlFor="male">Tag for quoteestimation</label>
                </div>
            </div>
            <div className="button button__add" onClick={() => generateOffer()}>
                CREATE NEW QUOTE
            </div>
        </FormState>
    );
}

export default Step3;
