import { API_ADMIN_URL_TEMPLATES } from "../config";
import { getToken } from "../Utils/Auth";
import { getWithToken, postWithToken } from "./apiRequets";

export function getTemplates(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        const token = await getToken();

        // eslint-disable-next-line quotes
        const parameter = {};
        const response = await getWithToken(token, API_ADMIN_URL_TEMPLATES, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

export function setTemplates(body: any): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        const token = await getToken();

        // eslint-disable-next-line quotes
        const parameter = {};
        const response = await postWithToken(token, API_ADMIN_URL_TEMPLATES, parameter, body) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

export default { getTemplates, setTemplates };
