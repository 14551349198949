import React, { useState } from "react";
import { ITask } from "../../../interfaces/ITask";

import { Draggable } from "react-beautiful-dnd";
import { secondsToHour, dateStringToInputDate, InputDateTodateString } from "../../../Utils/Utilities";
import { IWorkGroup } from "../../../interfaces/IWorkGroup";
import "./style.scss";
import { MdExpandLess } from "react-icons/md";
import { FaTrashAlt, FaTag, FaInfo } from "react-icons/fa";
import API from "./../../../api/apiHandler";
import { Message } from "../../../Core/Message";
import { EventMessage } from "../../../Core/Messages";
import { Utilities } from "../../../Core/Utilities";

function Task(props: {
    index: number;
    quoteId: string;
    task: ITask;
    indexPhase: number;
    workgroups: IWorkGroup[];
    quoteEditable: boolean;
}) {
    const [expand, setexpand] = useState(false);

    const notify = (text: string) => Utilities.printSuccess(text);

    function handleTaskChange(newTask: ITask) {
        Message.Send(EventMessage.EDIT_TASK, undefined, {
            indexPhase: props.indexPhase,
            indexTask: props.index,
            task: newTask
        });
    }

    async function handleOnCopyTag() {
        let response = await API.ID.GetTimeSafeTag(props.quoteId, props.task.Id);
        let a = JSON.parse(response.Id);
        const copyText = `[${props.task.Id}][COMPL=${a[1]}]`;
        navigator.clipboard.writeText(copyText);
        notify(`Copy id: ${copyText} successfull to clipboard`);
    }

    let wgList = props.workgroups.map((workgroup) => {
        return (
            <option key={workgroup.name} value={workgroup.name}>
                {workgroup.name}
            </option>
        );
    });

    function onWorkerChange(event: any) {
        let worker = props.workgroups.find((workerGroup) => workerGroup.name === event.target.value);
        if (!worker) return;
        let newTask: ITask = {
            ...props.task,
            WorkerGroup: worker.name,
            HourlyRate: worker.hourlyRate
        };

        handleTaskChange(newTask);
    }

    function open() {
        setexpand(true);
    }

    function close() {
        setexpand(false);
    }

    function stopProp(event: React.MouseEvent) {
        event.stopPropagation();
    }

    const handleTaskDelete = () => {
        console.log(props.indexPhase, props.index);
        Message.Send(EventMessage.DELETE_TASK, undefined, { indexPhase: props.indexPhase, indexTask: props.index });
    };

    let costPriceTemplate = () => (
        <>
            <div className="body__exapand-copy" onClick={handleOnCopyTag}>
                <FaTag></FaTag>
            </div>
            {props.quoteEditable && (
                <div className="body__exapand-delete" onClick={handleTaskDelete}>
                    <FaTrashAlt></FaTrashAlt>
                </div>
            )}
            <div className="task__header" onClick={() => open()}>
                <div className=" task__id">{`${props.indexPhase + 1}.${props.index + 1}`}</div>
                <div className="task__tsId">{`${[props.task.Id]}`}</div>
                <span className="task__item task__title">
                    <input
                        onClick={stopProp}
                        disabled={!props.quoteEditable}
                        type="text"
                        value={props.task.Title}
                        onChange={(e) => handleTaskChange({ ...props.task, Title: e.target.value })}
                    />
                    {props.task.Description && (
                        <div className="has-info">
                            <FaInfo className="has-info-icon"></FaInfo>
                            <div className="has-info-text">{props.task.Description}</div>
                        </div>
                    )}
                </span>

                <select
                    disabled={!props.quoteEditable}
                    onClick={stopProp}
                    className="task__item task__workgroup"
                    onChange={(e) => onWorkerChange(e)}
                    value={props.task.WorkerGroup || 0}>
                    {wgList}
                </select>
                <input
                    disabled={!props.quoteEditable}
                    onClick={stopProp}
                    className="task__item task__duration"
                    type="number"
                    value={secondsToHour(props.task.Duration)}
                    onChange={(e) => handleTaskChange({ ...props.task, Duration: Number(e.target.value) * 3600 })}
                />

                <div className="task__unit">h</div>

                <input
                    disabled={!props.quoteEditable}
                    onClick={stopProp}
                    type="number"
                    className="task__item task__hourly-rate"
                    value={props.task.HourlyRate}
                    onChange={(e) =>
                        handleTaskChange({ ...props.task, HourlyRate: Number.parseInt(e.target.value) })
                    }></input>
                <div className=" task__total">
                    {`CHF ${(props.task.HourlyRate * secondsToHour(props.task.Duration)).toFixed(2)}`}
                </div>
            </div>
            {expand && (
                <div className="task__body">
                    <textarea
                        disabled={!props.quoteEditable}
                        className="task--description"
                        rows={1}
                        cols={60}
                        value={props.task.Description}
                        onChange={(e) => handleTaskChange({ ...props.task, Description: e.target.value })}></textarea>
                    <input
                        disabled={!props.quoteEditable}
                        className="body__exapand-date"
                        type="date"
                        value={dateStringToInputDate(props.task.Date)}
                        onChange={(e) =>
                            handleTaskChange({ ...props.task, Date: InputDateTodateString(e.target.value) })
                        }></input>

                    <div className="body__exapand-less" onClick={() => close()}>
                        <MdExpandLess></MdExpandLess>
                    </div>
                </div>
            )}
        </>
    );

    let fixPriceTemplate = () => (
        <>
            <div className="body__exapand-copy" onClick={handleOnCopyTag}>
                <FaTag></FaTag>
            </div>
            {props.quoteEditable && (
                <div className="body__exapand-delete" onClick={handleTaskDelete}>
                    <FaTrashAlt></FaTrashAlt>
                </div>
            )}
            <div className="task__header" onClick={() => open()}>
                <div className=" task__id">{`${props.indexPhase + 1}.${props.index + 1}`}</div>
                <span className="task__item task__title">
                    <input
                        disabled={!props.quoteEditable}
                        onClick={stopProp}
                        type="text"
                        value={props.task.Title}
                        onChange={(e) => handleTaskChange({ ...props.task, Title: e.target.value })}
                    />
                    {props.task.Description && (
                        <div className="has-info">
                            <FaInfo className="has-info-icon"></FaInfo>
                            <div className="has-info-text">{props.task.Description}</div>
                        </div>
                    )}
                </span>
                <input
                    disabled={!props.quoteEditable}
                    onClick={stopProp}
                    type="number"
                    min="0"
                    className="task__item task__hourly-rate"
                    value={props.task.HourlyRate}
                    onChange={(e) =>
                        handleTaskChange({ ...props.task, HourlyRate: Number.parseInt(e.target.value) })
                    }></input>
                <div className=" task__total">{props.task.HourlyRate.toFixed(2)}</div>
            </div>
            {expand && (
                <div className="task__body">
                    <textarea
                        disabled={!props.quoteEditable}
                        className="task--description"
                        rows={1}
                        cols={60}
                        value={props.task.Description}
                        onChange={(e) => handleTaskChange({ ...props.task, Description: e.target.value })}></textarea>
                    <input
                        disabled={!props.quoteEditable}
                        className="body__exapand-date"
                        type="date"
                        value={dateStringToInputDate(props.task.Date)}
                        onChange={(e) =>
                            handleTaskChange({ ...props.task, Date: InputDateTodateString(e.target.value) })
                        }></input>

                    <div className="body__exapand-less" onClick={() => close()}>
                        <MdExpandLess></MdExpandLess>
                    </div>
                </div>
            )}
        </>
    );

    return (
        <>
            <Draggable
                isDragDisabled={!props.quoteEditable}
                draggableId={`task-${props.indexPhase}-${props.index}`}
                index={props.index}
                key={`task-${props.indexPhase}-${props.index}`}>
                {(provided) => (
                    <div
                        id={props.task.Id}
                        className="quoteedit__task"
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}>
                        {(props.task.TaskType === 0 || props.task.TaskType === 1) && costPriceTemplate()}
                        {props.task.TaskType === 2 && fixPriceTemplate()}
                    </div>
                )}
            </Draggable>
        </>
    );
}

export default Task;
