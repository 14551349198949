import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// MSAL configuration
export const configuration: Configuration = {
    auth: {
        clientId: "b1ea0cf9-34eb-4e90-a628-09dcb82d4262",
        authority: "https://login.microsoftonline.com/eef18931-6bc0-4ade-9c34-417736f3da14/",
        postLogoutRedirectUri: window.location.origin
    }
};
const pca = new PublicClientApplication(configuration);

// Component
const AppProvider = () => (
    <MsalProvider instance={pca}>
        <App />
        <ToastContainer />
    </MsalProvider>
);

ReactDOM.render(<AppProvider />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
