import React, { useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { faSignInAlt, faSignOutAlt, faCogs } from "@fortawesome/free-solid-svg-icons";
import "./Login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../molecules/Modal/Modal";
import Settings from "../Settings/Settings";
import { AccountData } from "../../Utils/Auth";

function Login() {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const isAuthenticated = useIsAuthenticated();
    let [showSettings, setShowSettings] = useState(false);
    const obj = AccountData.getUSerImage();

    var objectURL;
    if (isAuthenticated && obj) {
        objectURL = URL.createObjectURL(obj);
    }

    function toggleSettings() {
        setShowSettings(!showSettings);
    }
    function closeSettings() {
        setShowSettings(false);
    }

    return (
        <>
            {isAuthenticated && (
                <>
                    <div className="login-container">
                        <span className="login-container-name">{account ? `Howdy, ${account.name}` : ""}</span>
                        <img className="login-container-img" src={objectURL} alt="profileImg"></img>
                        <div className="login-container-btn" onClick={() => toggleSettings()}>
                            <FontAwesomeIcon icon={faCogs} size="2x"></FontAwesomeIcon>
                        </div>
                        <div className="login-container-btn" onClick={() => instance.logout()}>
                            <FontAwesomeIcon icon={faSignOutAlt} size="2x"></FontAwesomeIcon>
                        </div>
                    </div>
                    <Modal show={showSettings} close={closeSettings} title="Settings">
                        <Settings></Settings>
                    </Modal>
                </>
            )}
            {!isAuthenticated && (
                <div
                    className="login-container-btn"
                    onClick={() => {
                        console.log("login");
                        instance.loginRedirect();
                    }}>
                    <FontAwesomeIcon icon={faSignInAlt} size="2x"></FontAwesomeIcon>
                </div>
            )}
        </>
    );
}

export default Login;
