export async function getWithToken(
    accessToken: string,
    _url: string,
    _parameter: Record<string, string>
): Promise<any> {
    return new Promise(async (resolve, reject) => {
        const url: URL = new URL(_url);
        url.search = new URLSearchParams(_parameter).toString();

        const headers1 = new Headers();
        headers1.append("Authorization", `Bearer ${accessToken}`);
        const options = {
            method: "GET",
            headers: headers1
        };

        fetch(url.href, options)
            .then((response: Response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function postWithToken(
    accessToken: string,
    _url: string,
    _parameter: Record<string, string>,
    body: any
): Promise<any> {
    return new Promise(async (resolve, reject) => {
        const url: URL = new URL(_url);
        url.search = new URLSearchParams(_parameter).toString();

        const headers1 = new Headers();
        headers1.append("Authorization", `Bearer ${accessToken}`);
        const options = {
            method: "POST",
            headers: headers1,
            body: JSON.stringify(body)
        };

        fetch(url.href, options)
            .then((response: Response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
