/* eslint-disable no-unused-vars */

export const themeColors = [
    {"key" : "color-red",  "color" : "Red"},
    {"key" : "color-blue", "color" : "Blue"},
];

export const themeModes  = [
    {"key" : "light",  "mode" : "Light"},
    {"key" : "dark", "mode" : "Dark"},
];




export function setColor(color : string){

    var el =  document.documentElement.getElementsByClassName("App");
    if(el.length <= 0){return;}

    switch(color){
        
        case "color-red" :
            el[0].classList.remove("theme-color-blue");
            el[0].classList.add("theme-color-red");
            break;
        case "color-blue" :
            el[0].classList.remove("theme-color-red");
            el[0].classList.add("theme-color-blue");
            break;
    }
};


export function setMode( mode : string){

    var el =  document.documentElement.getElementsByClassName("App");
    if(el.length <= 0){return;}
    switch(mode){
        
        case "dark" :
            el[0].classList.remove("theme-light");
            el[0].classList.add("theme-dark");
            break;
        case "light" :
            el[0].classList.remove("theme-dark");
            el[0].classList.add("theme-light");
            break;
    }
};

/* eslint-enable no-unused-vars */