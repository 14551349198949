import React, { useState } from "react";
import { ICustomer } from "../../interfaces/ICustomer";
import { EMPTY_ORGANIZATION } from "../../interfaces/IOrganization";
import { IProject } from "../../interfaces/IProject";
import { IQuotePreview } from "../../interfaces/IQuotePreview";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

function NewQuoteForm(props: { customers: ICustomer[]; closeModal: Function }) {
    const [step, setStep] = useState<number>(0);

    let emptyQuote: IQuotePreview = {
        QuoteId: -1,
        QuoteState: 0,
        QuoteTitel: "",
        Versions: []
    };

    let emptyCustomer: ICustomer = {
        CustomerNo: "",
        Organization: EMPTY_ORGANIZATION,
        Projects: []
    };

    let emptyProject: IProject = {
        Organization: -1,
        ProjectId: -1,
        ProjectName: "",
        ProjectNo: "",
        Quotes: [],
        QuotesPreview: []
    };

    const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>(emptyCustomer);
    const [selectedProject, setSelectedProject] = useState<IProject>(emptyProject);
    const [selectedQuote, setSelectedQuote] = useState<IQuotePreview>(emptyQuote);

    const next = () => {
        setStep(step + 1);
    };
    const back = () => {
        setStep(step - 1);
    };

    return (
        <div className="form form__newQuote">
            <div className="form-header">
                <span className={`dot ${step === 0 ? "active" : ""}`}></span>
                <span className={`dot ${step === 1 ? "active" : ""}`}></span>
                <span className={`dot ${step === 2 ? "active" : ""}`}></span>
            </div>
            {step === 0 && (
                <Step1
                    customers={props.customers}
                    selectedCustomer={selectedCustomer}
                    setSelectedCustomer={setSelectedCustomer}></Step1>
            )}
            {step === 1 && (
                <Step2
                    selectedCustomer={selectedCustomer}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    selectedQuote={selectedQuote}
                    setSelectedQuote={setSelectedQuote}></Step2>
            )}
            {step === 2 && (
                <Step3
                    selectedCustomer={selectedCustomer}
                    selectedProject={selectedProject}
                    selectedQuote={selectedQuote}
                    closeModal={props.closeModal}></Step3>
            )}
            <div className="form-footer">
                {step > 0 && step <= 2 && (
                    <div className="button button__back" onClick={() => back()}>
                        BACK
                    </div>
                )}
                {step >= 0 && step <= 1 && (
                    <button className="button button__next" onClick={() => next()}>
                        NEXT
                    </button>
                )}
            </div>
        </div>
    );
}
export default NewQuoteForm;

export function FormState(props: any) {
    return <div className="form__state">{props.children}</div>;
}
