import { API_ADMIN_URL } from "../config";
import { getToken } from "../Utils/Auth";
import { getWithToken } from "./apiRequets";

const URL_PROJECTS = "projects";

export async function getProjects(from: string | null, to: string | null, organizationid : string | null, projectid : string | null): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        const token = await getToken();
        let parameter = {};
        if (from !== null && to !== null) {
            parameter = { dtfrom: from, dtto: to };
        }
        if (organizationid !== null)
        {
            parameter ={organizationid : organizationid};
        }
        if (projectid !== null)
        {
            parameter ={projectid : projectid};
        }

        const response = await getWithToken(token, API_ADMIN_URL + URL_PROJECTS, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

export default { getProjects };
