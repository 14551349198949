import React from "react";
import { ICustomer } from "../../../../interfaces/ICustomer";
import { IProject } from "../../../../interfaces/IProject";
import ProjectListItem from "./ProjectListItem";
import "./style.scss";

function ProjectList(props: { customers: ICustomer[]; filter: string }) {
    let projectsTemp: IProject[] = [];

    (props.customers || []).forEach((customer: ICustomer) => {
        return customer.Projects.forEach((project: IProject) => {
            projectsTemp.push(project);
        });
    });
    const stringFilter = (project: IProject) => project.ProjectName.toLowerCase().includes(props.filter.toLowerCase());

    let ProjectList = projectsTemp

        .filter(stringFilter)
        .map((project: IProject) => (
            <ProjectListItem
                project={project}
                key={project.ProjectId}
                expand={projectsTemp.length <= 3}></ProjectListItem>
        ));

    return <div className="project-list">{ProjectList}</div>;
}

export default ProjectList;
