export enum EventMessage {
    SAVE_FILE = "SAVE_FILE",
    ADD_PHASE = "ADD_PHASE",
    ADD_TASK = "ADD_TASK",
    EDIT_TASK = "EDIT_TASK",
    DELETE_TASK = "DELETE_TASK",
    EDIT_PHASE = "EDIT_PHASE",
    DELETE_PHASE = "DELETE_PHASE",
    EDIT_QUOTE_NAME = "EDIT_QUOTE_NAME"
}
