import { ITask } from "./ITask";
import { ITimeSafeEntry } from "./ITimeSafeEntry";

export enum PhaseState {
    DRAFT = 0,
    IN_REVIEW = 1,
    RELEASED = 2,
    APPROVED = 3,
    COMPLETED = 4
}

interface IProjectPhase {
    Id: string;
    Name: string;
    State: PhaseState;
    Type: number;
}

export interface IProjectPhaseOffer extends IProjectPhase {
    Tasks: ITask[];
}

export interface IProjectPhaseTimeSafeOffer extends IProjectPhase {
    Tasks: ITimeSafeEntry[];
}
