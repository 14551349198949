import { toast } from "react-toastify";

export class Utilities {
    public static Exists(object: any): boolean {
        return object !== undefined && object !== null;
    }

    public static printSuccess(text: string) {
        toast.success(text, {
            autoClose: 2000,
            position: toast.POSITION.BOTTOM_CENTER,
            pauseOnFocusLoss: false
        });
    }

    public static printError(text: string) {
        toast.error(text, {
            autoClose: 2000,
            position: toast.POSITION.BOTTOM_CENTER,
            pauseOnFocusLoss: false
        });
    }
}
