import moment from "moment";
import * as dotenv from "dotenv";

dotenv.config({ path: `${__dirname}/.dev.env` });

moment.locale("de-ch");

export function secondsToHour(seconds: number): number {
    return seconds / 3600;
}

export function dateStringToInputDate(dateString: string): string {
   return dateString;
}

export function InputDateTodateString(inputDate: string): string {
    const options: Intl.DateTimeFormatOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
    };
    const event = new Date(inputDate).toLocaleDateString("de-DE", options);
    return event;
}

function log(data: string | Function, color: string, FnData: any = null): void {
    if (isDev()) {
        if (typeof data === "string") {
            console.log(`%c${data}`, `color:${color}`);
        } else {
            data(FnData);
        }
    }
}

logInfo("You are in Dev mode:-)");

function isDev(): boolean {
    return process.env.NODE_ENV === "development";
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function logDebug(data: string | Function, FnData: any = null): void {
    log(data, "#001100", FnData);
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function logInfo(data: string): void {
    log(data, "#0000AA");
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function logWarn(data: string): void {
    log(data, "#AAAA00");
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function logError(data: string): void {
    log(data, "#DD0000");
}

export function isNumber(n: any): number {
    if (isNaN(n)) return 0;
    if (!isFinite(n)) return 0;
    if (!isNaN(n)) return Number(n);
    return 0;
}
