import { API_ADMIN_URL } from "../config";
import { getToken } from "../Utils/Auth";
import { getWithToken, postWithToken } from "./apiRequets";

const URL_SETTTINGS = "Settings";

function getAllSettings(): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        const token = await getToken();
        // eslint-disable-next-line quotes
        const parameter = {};
        const response = await getWithToken(token, API_ADMIN_URL + URL_SETTTINGS, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

function getSetting(key: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        const token = await getToken();
        // eslint-disable-next-line quotes
        const parameter = { key: key };
        const response = await getWithToken(token, API_ADMIN_URL + URL_SETTTINGS, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

function settSetting(key: string, body: any): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        const token = await getToken();
        // eslint-disable-next-line quotes
        const parameter = { key: key };
        const response = await postWithToken(token, API_ADMIN_URL + URL_SETTTINGS, parameter, body) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

export default { getAllSettings, getSetting, settSetting };
