import { getToken } from "./Auth";



export function GetTsApiBarerToken(username: string, password: string, secret:string): Promise<string> {
    return new Promise<any>(async (resolve, reject) => {
            const response = await getToken(username,password,secret)
            .catch((error: string) => reject(error));
        return response;
    });
}


//export default { GetTsApiBarerToken };
