import React from "react";
import { Link } from "react-router-dom";
import { IQuotePreview } from "../../../../interfaces/IQuotePreview";
import { FiExternalLink } from "react-icons/fi";
import { DataManager } from "../../../../Utils/DataManager";
import { DataManagerKeys } from "../../../../Utils/DataManagerKeys";
import { ISettingEnum } from "../../../../interfaces/ISetting";

function QuoteListItem(props: { quote: IQuotePreview }) {
    const states: ISettingEnum = DataManager.GetValue(DataManagerKeys.TEMPLATE_OFFER_STATES);

    return (
        <div className="quote-item">
            <header>
                <div className="col">NAME</div>
                <div className="col">ID</div>
                <div className="col">ACTUAL VERSION</div>
                <div className="col">VERSION COUNT</div>
                <div className="col">STATE</div>
                <div className="col"></div>
            </header>
            <Link
                to={{
                    pathname: "/offer",
                    state: {
                        id: props.quote.QuoteId,
                        version: props.quote.Versions[props.quote.Versions.length - 1]
                    }
                }}>
                <div className="row">
                    <div className="col">{props.quote.QuoteTitel}</div>
                    <div className="col">{props.quote.QuoteId}</div>
                    <div className="col">{props.quote.Versions[props.quote.Versions.length - 1]}</div>
                    <div className="col">{props.quote.Versions.length}</div>
                    <div className="col">
                        <div className="state">{states.Data[props.quote.QuoteState]}</div>
                    </div>
                    <div className="col">
                        <FiExternalLink />
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default QuoteListItem;
