import React from "react";
import { IQuoteSummary } from "../../../interfaces/IQuoteSummery";
import "./style.scss";
import { FaEllipsisV } from "react-icons/fa";
import ContextMenu from "../../molecules/ContextMenu/ContextMenu";
import { Message } from "../../../Core/Message";
import { EventMessage } from "../../../Core/Messages";

function Directory(props: { offer: IQuoteSummary }) {
    let refs = props.offer.OfferObject.Phases.map((phase) => {
        return (
            <div className="quote-edit-document__directory-item" key={phase.Id}>
                <div
                    className="directory-item--title"
                    onClick={() => {
                        document.getElementById(phase.Id)?.scrollIntoView();
                    }}>
                    {phase.Name}
                </div>
                <div className="directory-item--menu">
                    <ContextMenu openButtonConntent={<FaEllipsisV></FaEllipsisV>}>
                        <ul className="context-menu">
                            <li
                                className="context-menu-item"
                                onClick={() => Message.Send(EventMessage.DELETE_PHASE, null, { phaseId: phase.Id })}>
                                Delete
                            </li>
                        </ul>
                    </ContextMenu>
                </div>
            </div>
        );
    });

    return <div className="quote-edit-document__directory">{refs}</div>;
}

export default Directory;
