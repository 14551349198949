import React from "react";
import { ICustomer } from "../../../../interfaces/ICustomer";
import { IFilter } from "../../../../interfaces/IFilter";
import { IProject } from "../../../../interfaces/IProject";
import { QuoteState } from "../../../../interfaces/IQuote";
import { IQuotePreview } from "../../../../interfaces/IQuotePreview";
import QuoteListItem from "./QuoteListItem";

function QuoteList(props: { customers: ICustomer[]; selectedFilter: Map<string, IFilter>; filter: string }) {
    let QuoteListTemp: IQuotePreview[] = [];

    console.log(props.selectedFilter);

    (props.customers || []).forEach((customer: ICustomer) => {
        return customer.Projects.forEach((project: IProject) => {
            return project.QuotesPreview.forEach((quote: IQuotePreview) => {
                if (
                    //Dont filter if no filter is selected
                    (props.selectedFilter.size <= 1 && quote.QuoteState !== QuoteState.ARCHIVED) ||
                    //Filter Open
                    (quote.QuoteState === QuoteState.OPEN && props.selectedFilter.has("QUOTE_STATE_OPEN")) ||
                    // Filter Ready
                    (quote.QuoteState === QuoteState.READY && props.selectedFilter.has("QUOTE_STATE_READY")) ||
                    // Filter Sent
                    (quote.QuoteState === QuoteState.SENT && props.selectedFilter.has("QUOTE_STATE_SENT")) ||
                    // Filter Sent
                    (quote.QuoteState === QuoteState.WORK && props.selectedFilter.has("QUOTE_STATE_WORK")) ||
                    // Filter Sent
                    (quote.QuoteState === QuoteState.DONE && props.selectedFilter.has("QUOTE_STATE_DONE")) ||
                    // Filter Sent
                    (quote.QuoteState === QuoteState.REJECTED && props.selectedFilter.has("QUOTE_STATE_REJECTED")) ||
                    //Show archived if show archived is selected
                    (quote.QuoteState === QuoteState.ARCHIVED && props.selectedFilter.has("QUOTE_FILTER_STATE_ARCHIVED")) 

                    //"[\"OPEN\",\"READY\",\"SENT\",\"WORK\",\"DONE\",\"REJECTED\",\"ARCHIVE…
                ) {
                    QuoteListTemp.push(quote);
                }
            });
        });
    });

    const stringFilter = (quote: IQuotePreview) => quote.QuoteTitel.toLowerCase().includes(props.filter.toLowerCase());

    let quoteList = QuoteListTemp.filter(stringFilter).map((quote: IQuotePreview) => (
        <QuoteListItem quote={quote} key={quote.QuoteId}></QuoteListItem>
    ));

    return <div className="quote-list">{quoteList}</div>;
}

export default QuoteList;
