import React, { useState } from "react";
import { ICustomer } from "../../../interfaces/ICustomer";
import { IFilter } from "../../../interfaces/IFilter";
import SelectedFilter from "../SelectedFilter";
import CustomerList from "./CustomerList/CustomerList";
import ProjectList from "./ProjectList/ProjectList";
import QuoteList from "./QuoteList/QuoteList";

function FilterList(props: {
    selectedFilters: Map<string, IFilter>;
    customers: ICustomer[];
    linkstate: any;
    removeFilterFn: Function;
}) {
    const [filter, setFilter] = useState<string>(props.linkstate.content || "");

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>, callbackFn: Function) {
        callbackFn(event.target.value);
    }

    let selectedFilters = [...props.selectedFilters.values()].map((entrie) => {
        if (entrie.type !== "GROUPBY") {
            return (
                <SelectedFilter filter={entrie} removeFilterFn={props.removeFilterFn} key={entrie.id}></SelectedFilter>
            );
        }
    });

    return (
        <>
            <div className="filter-main__container">
                <div className="container__filter-input">
                    <input
                        type="text"
                        placeholder="Search..."
                        onChange={(event) => handleInputChange(event, setFilter)}></input>
                </div>
                <div className="container__filter-selected">{selectedFilters}</div>
                <div className="divider">
                    <hr></hr>
                </div>
                <div className="info">Select keywords on the left. If no filter is selected, all quotes except "archived" will be shown.</div>
                {props.linkstate.filter === "quotes" && (
                    <QuoteList
                        customers={props.customers}
                        selectedFilter={props.selectedFilters}
                        filter={filter}></QuoteList>
                )}
                {props.linkstate.filter === "customers" && (
                    <CustomerList customers={props.customers} filter={filter}></CustomerList>
                )}
                {props.linkstate.filter === "projects" && (
                    <ProjectList customers={props.customers} filter={filter}></ProjectList>
                )}
            </div>
        </>
    );
}

export default FilterList;
