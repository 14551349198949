import { getToken } from "../Utils/Auth";
import { getWithToken, postWithToken } from "./apiRequets";
import { IQuoteSummary } from "../interfaces/IQuoteSummery";
import { API_ADMIN_URL } from "../config";

const URL_OFFER = "offer";

function GetVersions(id: number): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        const token = await getToken();
        const parameter = { id: id.toString() };
        const response = await getWithToken(token, API_ADMIN_URL + URL_OFFER, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

function GetQuote(id: number, version: string): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        const token = await getToken();
        const parameter = { id: id.toString(), version: version };
        const response = await getWithToken(token, API_ADMIN_URL + URL_OFFER, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body: IQuoteSummary = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

export async function SaveQuote(offer: IQuoteSummary): Promise<any> {
    return new Promise(async (resolve, reject) => {
        const token = await getToken();
        let parameter = { id: offer.id, version: offer.version };
        const response = await postWithToken(token, API_ADMIN_URL + URL_OFFER, parameter, offer).catch((error) =>
            reject(error)
        );
        if (response.ok) {
            resolve(await (<string>response.json()));
        } else {
            reject(response.statusText);
        }
    });
}

export default { GetVersions, GetQuote, SaveQuote };
