import { API_ADMIN_URL } from "../config";
import { IWorkGroup } from "../interfaces/IWorkGroup";
import { getToken } from "../Utils/Auth";
import { getWithToken } from "./apiRequets";

const URL_WORKGROUP = "Workgroups";

async function getAllWorkgroups(): Promise<IWorkGroup[]> {
    return new Promise(async (resolve, reject) => {
        const token = await getToken();
        const parameter = {};
        const response = await getWithToken(token, API_ADMIN_URL + URL_WORKGROUP, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

export default { getAllWorkgroups };
