export const API_BASE_URL = "https://thanosv2admin.azurewebsites.net/api/";
export const API_ADMIN_URL = `${API_BASE_URL}manager/`;
export const API_ADMIN_URL_TEMPLATES = `${API_BASE_URL}templates`;
export const API_SWAGGER_URL = `${API_BASE_URL}swagger/ui`;

//-----------------CS - TimeSafe-----------------
export const API_TIMESAFE_URL = `${API_BASE_URL}TimeSafe/`;
export const API_ORDERING_URL = `${API_TIMESAFE_URL}Ordering/`;
export const API_ORGANIZATION_URL = `${API_TIMESAFE_URL}organizations`;


//-----------------TimeSafe-----------------
export const TSLOGIN_USERNAME = "AzureAd\\RojMüller"; //TODO: from settings
export const TSLOGIN_PASSWORD = "5162.Eee"; //TODO: from settings
export const TSLOGIN_SECRET = "QXp1cmVBZFxSb2pN/GxsZXI6NTk2Mi5FZWU="; //TODO: from settings

export const TSURL_BASE_API = "http://localhost:80"; //TODO: Replace link
export const TSURL_VERSIONSTR = "/api/v1"; //TODO: Replace link
export const TSURL_TOKEN = `${TSURL_BASE_API}${TSURL_VERSIONSTR}/Token`;
export const TSURL_ORGANIZATIONS = `${TSURL_BASE_API}${TSURL_VERSIONSTR}/Organizations`;
export const TSURL_BASE_URL = "https://vmthanossecure.net/api/V1/";
export const TSURL_ADRESSEN = `${TSURL_BASE_API}Adressen/`;
export const TSURL_ANSTELLUNGSVERHAELTNISSE = `${TSURL_BASE_API}Anstellungsverhältnisse/`;
export const TSURL_FEIERTAGE = `${TSURL_BASE_API}Feiertage/`;
export const TSURL_KOSTENTRAEGER = `${TSURL_BASE_API}Kostenträger/`;
export const TSURL_LIZENZINFO = `${TSURL_BASE_API}LizenzInformation/`;
export const TSURL_MANDANTENEINSTELLUNGEN = `${TSURL_BASE_API}Mandanteneinstellungen/`;
export const TSURL_MITARBEITER = `${TSURL_BASE_API}Mitarbeiter/`;
export const TSURL_MITARBEITER_LEISTERFASSUNG = `${TSURL_BASE_API}MitarbeiterLeistungserfassung/`;
export const TSURL_PROJEKTE = `${TSURL_BASE_API}Projekte/`;
export const TSURL_PROJEKTE_LEISTUNGSERFASSUNG = `${TSURL_BASE_API}ProjekteLeistungserfassung/`;
export const TSURL_PROJEKT_TAETIGKEITEN = `${TSURL_BASE_API}ProjektTätigkeiten/`;
export const TSURL_PROJEKT_TEAM_MITGLIEDER = `${TSURL_BASE_API}ProjektTeammitglieder/`;
export const TSURL_PROJEKT_TYPEN = `${TSURL_BASE_API}Projekttypen/`;
export const TSURL_STAMMDATENTAETIGKEITEN = `${TSURL_BASE_API}StammdatenTätigkeiten/`;
export const TSURL_VERSION = `${TSURL_BASE_API}Version/`;
export const TSURL_ZEITEINTRAEGE = `${TSURL_BASE_API}Zeiteinträge/`;