import React from "react";

import Task from "../Task/Task";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAltV } from "@fortawesome/free-solid-svg-icons";
import { IWorkGroup } from "../../../interfaces/IWorkGroup";
import "./style.scss";
import { secondsToHour } from "../../../Utils/Utilities";
import { IProjectPhaseOffer } from "../../../interfaces/IProjectPhase";
import { Message } from "../../../Core/Message";
import { EventMessage } from "../../../Core/Messages";
import ContextMenu from "../../molecules/ContextMenu/ContextMenu";

function Phase(props: {
    phase: IProjectPhaseOffer;
    index: number;
    workgroups: IWorkGroup[];
    switchEnabled: boolean;
    quoteId: string;
    quoteEditable: boolean;
}) {
    let taskList = props.phase.Tasks?.map((task, index) => (
        <Task
            workgroups={props.workgroups}
            task={task}
            index={index}
            key={`t-${task.Id}`}
            indexPhase={props.index}
            quoteId={props.quoteId}
            quoteEditable={props.quoteEditable}></Task>
    ));

    function handleOnTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
        Message.Send(EventMessage.EDIT_PHASE, undefined, {
            phaseIndex: props.index,
            phase: { ...props.phase, Name: event.target.value }
        });
    }
    let sumTime = props.phase.Tasks.map((task) => {
        switch (task.TaskType) {
            case 0:
            case 1:
                return secondsToHour(task.Duration);

            default:
                return 0;
        }
    });
    let sumTask = props.phase.Tasks.map((task) => {
        switch (task.TaskType) {
            case 0:
            case 1:
                return secondsToHour(task.Duration) * task.HourlyRate;
            case 2:
                return task.HourlyRate;
            default:
                return 0;
        }
    });

    let sum = 0;
    if (sumTask.length > 0) {
        sum = (sumTask || []).reduce((a, b) => a + b);
    }

    let sumTime1 = 0;
    if (sumTime.length > 0) {
        sumTime1 = (sumTime || []).reduce((a, b) => a + b);
    }

    const handleAddTask = (TaskType: number) => {
        Message.Send(EventMessage.ADD_TASK, undefined, { phaseIndex: props.index, taskType: TaskType });
    };

    return (
        <Draggable
            isDragDisabled={!props.quoteEditable}
            draggableId={`phase-${props.index}`}
            index={props.index}
            key={`phase-${props.index}`}>
            {(provided) => (
                <div className="offerlist--phase" {...provided.draggableProps} ref={provided.innerRef}>
                    <div className="phase--title" id={props.phase.Id}>
                        <input
                            className="title--input"
                            type="text"
                            disabled={!props.quoteEditable}
                            value={props.phase.Name}
                            onChange={handleOnTitleChange}></input>
                        <div className="phase--title-filler" />
                        <div
                            {...provided.dragHandleProps}
                            className={props.switchEnabled ? "phase_move-icon visible" : "phase_move-icon"}>
                            <FontAwesomeIcon icon={faArrowsAltV} size="1x"></FontAwesomeIcon>
                        </div>
                    </div>

                    <Droppable droppableId={`task-container-${props.index}`} type={"task"}>
                        {(provided) => (
                            <div className="phase--task-list" {...provided.droppableProps} ref={provided.innerRef}>
                                {taskList}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                    <div className="phase--footer">
                        {props.quoteEditable && (
                            <ContextMenu openButtonConntent={<div className="add-task__button">Add Task</div>}>
                                <ul className="phase--add-phase__submenu">
                                    <li onClick={() => handleAddTask(1)}>By hourly rate</li>
                                    <li onClick={() => handleAddTask(2)}>By fixed price</li>
                                </ul>
                            </ContextMenu>
                        )}
                        <div className="title__sum-time">{`${sumTime1.toFixed(2)} h`}</div>
                        <div className="title__sum">{`CHF ${sum.toFixed(2)}`}</div>
                    </div>
                </div>
            )}
        </Draggable>
    );
}

export default Phase;
