import React from "react";

function Modal(props: any) {
    function closeBubbling(event: React.MouseEvent) {
        if (event.target instanceof Element) {
            if (event.target.classList.contains("modal")) {
                // TODO Test without closing modal
                // props.close();
            }
        }
    }

    if (!props.show) {
        return null;
    }
    return (
        <div className="modal" onClick={(event) => closeBubbling(event)}>
            <div className="modal-content">
                <div className="modal-header">
                    <span className="close" onClick={() => props.close()}>
                        &times;
                    </span>
                    <h3>{props.title}</h3>
                </div>
                <div className="modal-body">
                    <div>{props.children}</div>
                </div>
            </div>
        </div>
    );
}
export default Modal;
