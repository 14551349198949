import { getAccessToken } from "../Utils/Auth";

const graphMeEndpoint = "https://graph.microsoft.com/v1.0/me";

const graphEndpoint = "https://graph.microsoft.com/v1.0/";

export async function callMSGraphPhoto(): Promise<any> {
    const t = await getAccessToken(["User.Read"]);

    const headers = new Headers();
    const bearer = `Bearer ${t}`;

    headers.append("Authorization", bearer);
    headers.append("Content-Type", "image/jpeg");

    const options = {
        method: "GET",
        headers: headers
    };
    const result = await fetch(`${graphMeEndpoint}/photos/96x96/$value`, options);
    try {
        return Promise.resolve(await result.blob());
    } catch {
        return Promise.reject("ERror");
    }
}

export async function callMSGraphUser() {
    const t = await getAccessToken(["User.Read"]);
    const headers = new Headers();
    const bearer = `Bearer ${t}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return await fetch(`${graphMeEndpoint}`, options)
        .then((response: Response) => response.json())
        .catch((error) => console.log(error));
}

export async function getCalendarEvents() {
    const t = await getAccessToken(["Calendars.Read"]);
    const headers = new Headers();
    const bearer = `Bearer ${t}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    const date = `${new Date().toISOString().slice(0, 10)} ${new Date().toLocaleTimeString("de-CH")}`;

    return await fetch(
        `${graphMeEndpoint}/events?$filter=start/dateTime ge '${date}' &$top=3&$orderby=start/datetime`,
        options
    )
        .then((response: Response) => response.json())
        .catch((error) => console.log(error));
}

// https://graph.microsoft.com/v1.0/groups/{group-id}/members?$count=true

export async function getGroupMember() {
    const t = await getAccessToken(["Calendars.Read"]);
    const headers = new Headers();
    const bearer = `Bearer ${t}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return await fetch(`${graphEndpoint}/groups/cbc19adc-7210-4bfc-b4a0-7791c2a47e89/members`, options)
        .then((response: Response) => response.json())
        .catch((error) => console.log(error));
}
