import TimeSafeOffer from "./apiTimeSafeOffer";
import ID from "./apiNextId";
import Quote from "./apiQuote";
import Templates from "./apiTemplates";
import Workgroups from "./apiWorkgroups";
import EntrySummary from "./apiEntrySummary";
import Projects from "./apiProjects";
import ProjectEntrys from "./apiProjectEntrys";
import Customer from "./apiCustomer";
import Settings from "./apiSettings";
import TimeSafeFunctions from "./apiTimesafeFunctions";
import OrderingTransportComp from "./apiOrderingTransportComp";
import OrderingCurrencies from "./apiOrderingCurrencies";
import OrderingDistributors from "./apiOrderingDistributors";
import OrderingOrderId from "./apiOrderingOrderId";
import TimeSafeProjectPhases from "./apiTimesafeProjectPhases";
import TimeSafeMaterialTypes from "./apiTimesafeMaterialTypes";
import OrderingPaymentMethodes from "./apiOrderingPaymentMethodes";
import OrderingOrder from "./apiOrderingOrder";

/**
 * All API endpoints for Thanos
 */
export default {
    ID,
    Quote,
    Templates,
    TimeSafeOffer,
    Workgroups,
    Projects,
    EntrySummary,
    ProjectEntrys,
    Customer,
    Settings,
    TimeSafeFunctions,
    TimeSafeProjectPhases,
    TimeSafeMaterialTypes,
    OrderingTransportComp,
    OrderingCurrencies,
    OrderingDistributors,
    OrderingOrderId,
    OrderingOrder,
    OrderingPaymentMethodes
};
