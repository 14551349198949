export interface IOrganization {
    OrganizationId: number;
    Name: string;
    ClientNo: number;
    Street: string;
    City: string;
    Nation: string;
    NationCode: string;
    ZipCode: number;
    ContactMail: string;
    ContactPhone: string;
}

export const EMPTY_ORGANIZATION: IOrganization = {
    Name: "",
    ClientNo: -1,
    Nation: "",
    NationCode: "",
    OrganizationId: -1,
    Street: "",
    City: "",
    ZipCode: -1,
    ContactMail: "",
    ContactPhone: ""
};
