import React, { ReactElement } from "react";
import "./style.scss";

function ContextMenu(props: { openButtonConntent: ReactElement; children: React.ReactChild }) {
    return (
        <div className="context-menu">
            <div className="context-menu--btn">
                <button>{props.openButtonConntent}</button>
                <div className="context-menu--content">{props.children}</div>
            </div>
        </div>
    );
}
export default ContextMenu;
