import React from "react";
import { ICustomer } from "../../../../interfaces/ICustomer";
import CustomerListItem from "./CustomerListItem";

function CustomerList(props: { customers: ICustomer[]; filter: string }) {
    //Multiple filters?
    let Customer = props.customers.filter(
        (customer: ICustomer) =>
            customer.Organization.Name.toLowerCase().includes(props.filter.toLowerCase()) ||
            customer.CustomerNo.includes(props.filter.toLowerCase())
    );

    let CustomerList = Customer.map((customer: ICustomer) => (
        <CustomerListItem
            customer={customer}
            key={customer.CustomerNo}
            expand={Customer.length <= 3}></CustomerListItem>
    ));

    return <div className="customer-list">{CustomerList}</div>;
}

export default CustomerList;
