/*
const express = require("express");
const cors = require("cors");
*/

/*
export async function getWithUsernamePw(
    username: string,
    pw: string,
    _url: string
): Promise<any> {
    //usernamePw = encrypt(username,pw);
    var usernamePw = "YXp1cmVhZFxSb2pN/GxsZXI6NTk2Mi5FZWU=";  //TODO: replace with conversion of u/pw
    return new Promise(async (resolve, reject) => {
        const url: URL = new URL(_url);

        const headers1 = new Headers();
        headers1.append("Authorization", `Basic ${usernamePw}`);
        const options = {
            method: "POST",
            headers: headers1
        };

        fetch(url.href, options)
            .then((response: Response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
*/
export async function getWithToken(
    accessToken: string,
    _url: string,
    _parameter: Record<string, string>
): Promise<any> {
    return new Promise(async (resolve, reject) => {
        const url: URL = new URL(_url);
        url.search = new URLSearchParams(_parameter).toString();

        const headers1 = new Headers();
        headers1.append("Authorization", `Bearer ${accessToken}`);
        const options = {
            method: "GET",
            headers: headers1
        };

        fetch(url.href, options)
            .then((response: Response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function postWithToken(
    accessToken: string,
    _url: string,
    _parameter: Record<string, string>,
    body: any
): Promise<any> {
    return new Promise(async (resolve, reject) => {
        const url: URL = new URL(_url);
        url.search = new URLSearchParams(_parameter).toString();

        const headers1 = new Headers();
        headers1.append("Authorization", `Bearer ${accessToken}`);
        const options = {
            method: "POST",
            headers: headers1,
            body: JSON.stringify(body)
        };

        fetch(url.href, options)
            .then((response: Response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function postUserPwToken(
    _url: string,
    user: string,
    pw: string,
    secret: string
): Promise<any> {
    return new Promise(async (resolve, reject) => {
        const url: URL = new URL(_url);

        


        if(secret == ""){
            secret = encodeUserPw(user,pw);
        }

        
        const headers1 = new Headers();
        headers1.append("Authorization", `Basic ${secret}`);
        //headers1.append("Authorization", `Basic ${(base64.encode(user + ":" + pw))}`);
        headers1.append("Content-Type", "*/*");
        
        headers1.append("Content-Length", "0");
        headers1.append("Connection", "keep-alive");
        headers1.append("Access-Control-Allow-Origin", "*");
        headers1.append("Access-Control-Allow-Credentials", "true");
        headers1.append("Access-Control-Allow-Headers", "Content-Type");
        headers1.append("Access-Control-Allow-Methods", "GET,PUT,POST,OPTIONS");
        headers1.append("Access-Controll-Allow-Domain","*");

        fetch(url.href, {headers: headers1, method: "POST"})
            .then((response: Response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        

        
        /*
        //- CORS functions, cause problem with existing async_hooks 
        const headers1 = new Headers();
        headers1.append("Authorization", `Basic ${secret}`);
        //headers1.append("Authorization", `Basic ${(base64.encode(user + ":" + pw))}`);
        headers1.append("Content-Type", "application/json");
        
        var corsOptions = {
            origin: "http://localhost:*",
            optionsSuccessStatus: 200 // For legacy browser support
        };
        const app = express();
        app.use(cors(corsOptions));
        
        fetch(url.href, {headers: headers1, method: "POST"})
        .then((response: Response) => {
            resolve(response);
        })
        .catch((error) => {
            reject(error);
        });*/
        
    });
}

function encodeUserPw(user: string, pw: string) : string{
    //-----------------convert user/pw to base64 alternative
    /*
    let userpw_b64 = btoa(`${user}:${pw}`);
    console.log(`userpw_b64: ${userpw_b64}`);
    */

    //python code
    var iconv = require("iconv-lite");
    let str = iconv.encode(`${user}:${pw}`, "win1250");
    console.log(`user:pw=  ${user}:${pw}`);
    let buff = new Buffer(str);
    let base64data = buff.toString("base64");
    let userpw_b64_long = base64data.toString();
    console.log(`userpw_b64 py script: ${userpw_b64_long}`);
    return userpw_b64_long;
}
