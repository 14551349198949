import React from "react";
import { Document, Page, Text, StyleSheet, Image, View, Font } from "@react-pdf/renderer";
import { IProjectPhaseOffer } from "../../interfaces/IProjectPhase";
import { IQuoteSummary } from "../../interfaces/IQuoteSummery";
import Logo from "./../../assets/images/Coresight.png";
import { ITask } from "../../interfaces/ITask";
import { secondsToHour } from "../../Utils/Utilities";
import { DataManager } from "../../Utils/DataManager";
import { DataManagerKeys } from "../../Utils/DataManagerKeys";
import { ISettingEnum } from "../../interfaces/ISetting";
import moment from "moment";
// Create styles

Font.register({
    family: "Open Sans",
    fonts: [
        { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf" },
        { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-300.ttf", fontWeight: 300 },
        { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf", fontWeight: 600 }
    ]
});

const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        fontWeight: 300,
        fontFamily: "Open Sans",
        fontSize: 10,
        padding: "2cm"
    },
    section: {
        margin: "1cm 0cm"
    },
    header: {
        fontSize: 10,
        marginBottom: 20,
        textAlign: "center",
        color: "grey"
    },
    image: {
        position: "absolute",
        right: "1cm",
        width: "5cm"
    },
    adress: {
        marginTop: "0cm",
        marginBottom: "4.5cm",
        fontWeight: 200,
        fontFamily: "Open Sans"
    },
    adressCustomer: {
        position: "absolute",
        marginTop: "6cm",
        width: "7cm",
        right: "1cm",
        marginBottom: "2.5cm",
        fontWeight: 200,
        fontFamily: "Open Sans"
    },
    quotedetails: {
        fontWeight: 200,
        fontFamily: "Open Sans"
    },

    table: {
        display: "flex",
        flexDirection: "column"
    },
    small: {
        width: "50%"
    },
    middle: {
        width: "70%"
    },
    full: {
        width: "100%"
    },
    tableContainer: {
        display: "flex",
        flexDirection: "column"
    },
    tableRow: {
        display: "flex",
        flexDirection: "row"
    },
    tableColumn: {
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-end"
    },
    cell: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "left",
        flexWrap: "wrap",
        marginBottom: "1mm"
    },
    cellMargin: {
        marginRight: "0.5cm"
    },
    offername: {
        color: "#ff0000",
        fontWeight: 600,
        textTransform: "uppercase",
        fontSize: "16px"
    },
    tableHeader: {
        borderBottom: "1px solid black",
        marginBottom: "2mm"
    },
    positionNumber: {
        maxWidth: "8mm"
    },
    total: {
        textAlign: "right",
        maxWidth: "14%"
    },
    cost: {
        textAlign: "right",
        maxWidth: "8%"
    },
    unit: {
        textAlign: "left",
        maxWidth: "7%"
    },
    count: {
        marginRight: "2mm",
        textAlign: "right",
        maxWidth: "10%"
    },
    phaseHeader: {
        fontWeight: 600
    },
    details: {
        fontSize: 8,
        marginLeft: "4mm",
        marginBottom: "3mm",
        maxWidth: "60%"
    },
    pageNumber: {
        fontSize: 8,
        bottom: 40,
        textAlign: "left",
        color: "black"
    },
    adressCoresight: {
        position: "absolute",
        fontSize: 6,
        bottom: 15,
        left: "2cm",
        textAlign: "left",
        color: "black",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },

    totalPhase: {
        textAlign: "right"
    },
    tableTotal: {
        marginTop: "20em"
    },
    tablePhase: {
        marginTop: "8em"
    }
});

// Create Document Component
function PdfQuote(props: {
    offer: IQuoteSummary;
    showTasks: boolean;
    showSubtotal: boolean;
    showDescription: boolean;
}) {
    const sumPhase = props.offer.OfferObject.Phases.map((phase: IProjectPhaseOffer) => {
        return phase.Tasks.map((task) => {
            switch (task.TaskType) {
                case 0:
                case 1:
                    return secondsToHour(task.Duration) * task.HourlyRate;
                case 2:
                    return task.HourlyRate;
                default:
                    return 0;
            }
        });
    });
    let sumPhase1 = sumPhase.map((sum) => (sum || []).reduce((a, b) => a + b));
    let sumTotal = (sumPhase1 || []).reduce((a, b) => a + b);

    const listPhase = props.offer.OfferObject.Phases.map((phase: IProjectPhaseOffer, indexPhase: number) => {
        let tasklist = phase.Tasks.map((task: ITask, index: number) => {
            return (
                <View style={[styles.tableContainer]} wrap={false}>
                    <View style={[styles.tableRow]}>
                        <Text style={[styles.cell, styles.positionNumber]}>{`${indexPhase + 1}.${index + 1}`}</Text>
                        <Text style={[styles.cell]}> {task.Title}</Text>
                        <Text style={[styles.cell, styles.count]}>
                            {task.TaskType <= 1 && secondsToHour(task.Duration)}
                        </Text>
                        <Text style={[styles.cell, styles.unit]}>{task.TaskType <= 1 && "h"}</Text>
                        <Text style={[styles.cell, styles.cost]}> {task.HourlyRate} </Text>
                        <Text style={[styles.cell, styles.total]}>
                            {task.TaskType <= 1 && `CHF ${(task.HourlyRate * secondsToHour(task.Duration)).toFixed(2)}`}
                            {task.TaskType === 2 && `CHF ${task.HourlyRate}`}
                        </Text>
                    </View>
                    {props.showDescription && (
                        <View style={[styles.tableRow]}>
                            <Text style={[styles.cell, styles.details]}>{task.Description}</Text>
                        </View>
                    )}
                </View>
            );
        });

        return (
            <>
                <View style={[styles.tableRow, styles.tablePhase]}>
                    <Text style={[styles.cell, styles.phaseHeader]}>{phase.Name}</Text>
                </View>
                {props.showTasks && tasklist}
                <View style={[styles.tableRow, styles.tablePhase]}>
                    {props.showSubtotal && (
                        <Text style={[styles.cell, styles.phaseHeader, styles.totalPhase]}>
                            CHF {sumPhase1[indexPhase].toFixed()}
                        </Text>
                    )}
                </View>
            </>
        );
    });

    const invoiceTypes: ISettingEnum = DataManager.GetValue(DataManagerKeys.TEMPLATE_INVOICE_TYPES);
    const invoiceType = invoiceTypes.Data[props.offer.OfferObject.InvoicingType];

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View>
                    <Image style={styles.image} src={Logo} />
                </View>

                <Text style={styles.adress}>
                    {"CORESIGHT AG  \n"}
                    {"Neugrüt 7 \n"}
                    {"9496 Balzers \n"}
                    {"Liechtenstein \n"}
                    {"Tel: +423 785 55 56 \n"}
                    {"E-Mail: accounting@coresight.li \n"}
                    {"Webseite: https://www.coresight.li \n"}
                </Text>

                <Text style={styles.adressCustomer}>
                    {props.offer.Organization.Name} {"\n"}
                    {props.offer.Organization.Street} {"\n"}
                    {`${props.offer.Organization.ZipCode} ${props.offer.Organization.City}`} {"\n"}
                    {`${props.offer.Organization.Nation}`} {"\n"}
                    {"\n"}
                    {`Balzers ${moment().format("DD. MMMM YYYY")}`}
                </Text>

                <View style={[styles.table, styles.small]}>
                    <View style={[styles.tableRow]}>
                        <Text style={[styles.cell, styles.cellMargin]}>QuoteNo</Text>
                        <Text style={[styles.cell]}>{props.offer.id}</Text>
                    </View>
                    <View style={[styles.tableRow]}>
                        <Text style={[styles.cell, styles.cellMargin]}>Version</Text>
                        <Text style={[styles.cell]}>{props.offer.version}</Text>
                    </View>
                    <View style={[styles.tableRow]}>
                        <Text style={[styles.cell, styles.cellMargin]}>Customer Number</Text>
                        <Text style={[styles.cell]}>{props.offer.Organization.ClientNo}</Text>
                    </View>
                    <View style={[styles.tableRow]}>
                        <Text style={[styles.cell, styles.cellMargin]}>Project Number</Text>
                        <Text style={[styles.cell]}>{props.offer.Project.ProjectNo}</Text>
                    </View>
                </View>

                <Text style={[styles.section, styles.offername]}>
                    {invoiceType} {props.offer.name}
                </Text>

                <View>
                    <View style={styles.table}>
                        <View style={[styles.tableRow, styles.tableHeader]} fixed={true}>
                            <Text style={[styles.cell]}>Position</Text>
                            <Text style={[styles.cell, styles.count]}>Anzahl</Text>
                            <Text style={[styles.cell, styles.unit]}>Einheit</Text>
                            <Text style={[styles.cell, styles.cost]}>Preis</Text>
                            <Text style={[styles.cell, styles.total]}>Total</Text>
                        </View>

                        {listPhase}
                    </View>
                </View>

                <View>
                    <View style={[styles.table, styles.tableTotal]}>
                        <View style={[styles.tableRow, styles.tableHeader]} fixed={true}>
                            <Text style={[styles.cell, styles.phaseHeader]}>Total</Text>
                            <Text style={[styles.cell, styles.phaseHeader, styles.totalPhase]}>
                                {`CHF ${sumTotal.toFixed()}`}
                            </Text>
                        </View>
                    </View>
                </View>

                <View style={[styles.table, styles.tableTotal]}>
                        <View style={[styles.tableRow, styles.cellMargin]} fixed={true}>
                            <Text style={[styles.cell, styles.cellMargin]}>Conditions:</Text>
                        </View>

                        <View style={[styles.tableRow, styles.cellMargin]} fixed={true}>
                            <Text style={[styles.cell, styles.cellMargin]}>- Terms and conditions according to mutual contract or: </Text>
                        </View>

                        <View style={[styles.tableRow, styles.cellMargin]} fixed={true}>
                            <Text style={[styles.cell, styles.cellMargin]}>{"  https://coresightdocs.blob.core.windows.net/agb/1000.01000.01_CORESIGHT_AGB.pdf"}</Text>
                        </View>

                        <View style={[styles.tableRow, styles.cellMargin]} fixed={true}>
                            <Text style={[styles.cell, styles.cellMargin]}>- Amounts not including tax</Text>
                        </View>

                        <View style={[styles.tableRow, styles.cellMargin]} fixed={true}>
                            <Text style={[styles.cell, styles.cellMargin]}>- Validity: 30 days</Text>
                        </View>
              
                </View>

                <View style={[styles.table, styles.full, styles.adressCoresight]} fixed>
                    <View style={[styles.tableColumn]}>
                        <Text style={[styles.cell, styles.cellMargin]}>Coresight AG</Text>
                        <Text style={[styles.cell, styles.cellMargin]}>Neugrüt 7</Text>
                        <Text style={[styles.cell, styles.cellMargin]}>9496 Balzers</Text>
                    </View>
                    <View style={[styles.tableColumn]}>
                        <Text style={[styles.cell]}></Text>
                        <Text style={[styles.cell, styles.cellMargin]}>info@coresight.li</Text>
                        <Text style={[styles.cell]}>+41 81 552 06 11</Text>
                    </View>
                    <View style={[styles.tableColumn]} fixed={true}>
                        <Text style={[styles.cell]}></Text>
                        <Text style={[styles.cell]}></Text>
                        <Text
                            style={[styles.cell, styles.cellMargin]}
                            render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                            fixed
                        />
                    </View>
                </View>
            </Page>
        </Document>
    );
}

export default PdfQuote;
