import React from "react";
import { Message } from "../../../Core/Message";
import { EventMessage } from "../../../Core/Messages";
import { IQuoteSummary } from "../../../interfaces/IQuoteSummery";
import { ISettingEnum } from "../../../interfaces/ISetting";
import { DataManager } from "../../../Utils/DataManager";
import { DataManagerKeys } from "../../../Utils/DataManagerKeys";
import { PrimaryButton } from "../../atoms/button";

import "./style.scss";

function QuoteEditDocumentHeader(props: {
    offer: IQuoteSummary;
    changeInvoiceType: Function;
    changeQuoteType: Function;
}) {
    // INVOICE TYPES
    const invoiceTypes: ISettingEnum = DataManager.GetValue(DataManagerKeys.TEMPLATE_INVOICE_TYPES);

    const invoiceTypeList = invoiceTypes.Data.map((invoiceType, index) => (
        <option value={index} key={index}>
            {invoiceType}
        </option>
    ));

    // QUOTE STATES
    const quoteStates: ISettingEnum = DataManager.GetValue(DataManagerKeys.TEMPLATE_OFFER_STATES);

    const quoteStateList = quoteStates.Data.map((quoteState, index) => (
        <option value={index} key={index}>
            {quoteState}
        </option>
    ));

    function handleOnInvoiceTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
        props.changeInvoiceType(event.target.value);
    }

    function handleOnQuoteChange(event: React.ChangeEvent<HTMLSelectElement>) {
        props.changeQuoteType(event.target.value);
    }
    console.log(props.offer.OfferObject);

    return (
        <div className="quote-edit-document-header__container">
            <div className="container__left">
                <div className="container__customer disabled">
                    <span>{props.offer.Organization.Name}</span>
                    <span>{props.offer.Organization.Street}</span>
                    <span>{`${props.offer.Organization.ZipCode} ${props.offer.Organization.City}`}</span>
                </div>
            </div>
            <div className="container__details">
                <div className="details__row">
                    <span className="row__title">Quotename</span>
                    <input
                        type="text"
                        className="row__value "
                        value={props.offer.name}
                        onChange={(e) =>
                            Message.Send(EventMessage.EDIT_QUOTE_NAME, null, { quoteName: e.target.value })
                        }></input>
                </div>
                <div className="details__row">
                    <span className="row__title">Quote type</span>
                    <select
                        className="row__value"
                        value={props.offer.OfferObject.InvoicingType}
                        onChange={handleOnInvoiceTypeChange}>
                        {invoiceTypeList}
                    </select>
                </div>
                <div className="details__row">
                    <span className="row__title">State</span>
                    <select className="row__value " value={props.offer.documentState} onChange={handleOnQuoteChange}>
                        {quoteStateList}
                    </select>
                </div>
                <div className="details__row">
                    <span className="row__title">Version</span>
                    <span className="row__value disabled">{props.offer.version}</span>
                </div>
                <div className="details__row">
                    <span className="row__title">Tag</span>
                    <span className="row__value disabled">{props.offer.tagForm}</span>
                </div>

                {props.offer.OfferObject.Modified.length > 0 && (
                    <div className="details__row">
                        <span className="row__title">Last edited: </span>
                        <span className="row__value disabled">
                            {props.offer.OfferObject.Modified[props.offer.OfferObject.Modified.length - 1].DateTime}
                        </span>
                        <span className="row__value disabled">
                            {
                                props.offer.OfferObject.Modified[props.offer.OfferObject.Modified.length - 1].Employee
                                    .FirstName
                            }
                        </span>
                    </div>
                )}
                {props.offer.OfferObject.Created && (
                    <div className="details__row">
                        <span className="row__title">Created: </span>
                        <span className="row__value disabled">{props.offer.OfferObject.Created.DateTime}</span>
                        <span className="row__value disabled">
                            {props.offer.OfferObject.Created.Employee.FirstName}
                        </span>
                    </div>
                )}

                {props.offer.OfferObject.Reviewed && props.offer.OfferObject.Reviewed.length > 0 && (
                    <div className="details__row">
                        {props.offer.OfferObject.Reviewed[0].DateTime === props.offer.OfferObject.Created.DateTime && (
                            <span className="row__title">Reviewer: </span>
                        )}
                        {props.offer.OfferObject.Reviewed[0].DateTime !== props.offer.OfferObject.Created.DateTime && (
                            <>
                                <span className="row__title">Reviewed: </span>
                                <span className="row__value disabled">
                                    {props.offer.OfferObject.Reviewed[0].DateTime}
                                </span>
                            </>
                        )}
                        <span className="row__value disabled">
                            {props.offer.OfferObject.Reviewed[0].Employee.FirstName}
                        </span>
                    </div>
                )}
                {quoteStates.Data[props.offer.documentState] === "OPEN" && (
                    <PrimaryButton onClick={() => props.changeQuoteType(1)}>Go to ready</PrimaryButton>
                )}
                {quoteStates.Data[props.offer.documentState] === "READY" && (
                    <PrimaryButton onClick={() => props.changeQuoteType(2)}>Go to review</PrimaryButton>
                )}
            </div>
        </div>
    );
}

export default QuoteEditDocumentHeader;
