import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { callMSGraphPhoto, callMSGraphUser } from "../api/graph";
import { User } from "@microsoft/microsoft-graph-types";

export function isAdmin(account: AccountInfo | null) {
    if (account === null) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let token: any = account.idTokenClaims;
    let roles: string[] = token.roles;
    if (!roles) return false;
    return roles.includes("Admin");
}

export async function getToken(): Promise<string> {
    return new Promise(async (resolve) => {
        let token = await getAccessToken(["https://thanosv2functions.azurewebsites.net/user_impersonation"]);
        resolve(token);
    });
}

export async function getAccessToken(scopes: string[]): Promise<string> {
    const accountData = AccountData.GetAccountData();
    if (!accountData.instance) return "";

    try {
        // Get the access token silently
        // If the cache contains a non-expired token, this function
        // will just return the cached token. Otherwise, it will
        // make a request to the Azure OAuth endpoint to get a token
        var silentResult = await accountData.instance.acquireTokenSilent({
            scopes: scopes,
            account: accountData.account
        });

        return silentResult.accessToken;
    } catch (err) {
        // If a silent request fails, it may be because the user needs
        // to login or grant consent to one or more of the requested scopes
        var interactiveResult = await accountData.instance.acquireTokenPopup({
            scopes: scopes
        });

        return interactiveResult.accessToken;
    }
}

export class AccountData {
    private static instance: IPublicClientApplication;
    private static account: AccountInfo;
    private static user: User;
    private static userImage: Blob;

    // Marked private to enforce singleton pattern.
    private constructor() {}

    public static GetAccountData() {
        return { instance: this.instance, account: this.account };
    }

    public static async setAccountData(instance: IPublicClientApplication, account: AccountInfo): Promise<any> {
        this.instance = instance;
        this.account = account;
        this.user = await callMSGraphUser();
        this.userImage = await callMSGraphPhoto().catch(console.log);
        return Promise.resolve();
    }

    public static getUser(): User {
        return this.user;
    }

    public static getUSerImage() {
        return this.userImage;
    }
}
