import React, { useEffect, useState } from "react";
import Navigation from "./components/Navigation/Navigation";
import "./App.scss";
import API from "./api/apiHandler";

import { BrowserRouter, Route, useLocation } from "react-router-dom";

import { AuthenticatedTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import Login from "./components/Login/Login";
import { AccountData, isAdmin } from "./Utils/Auth";
import { setColor, setMode } from "./Utils/theme/handleTheme";
import { AccountInfo } from "@azure/msal-common";
import FilterContainer from "./components/FilterContainer/FilterContainer";
import HomeContainer from "./components/HomeContainer/HomeContainer";
import QuoteContainer from "./components/QuoteContainer/QuoteContainer";
import TimeSafeOfferContainer from "./components/TimeSafeOfferContainer/TimeSafeOfferContainer";
import ThanosV1Container from "./components/ThanosV1/ThanosV1Container";
import { ICustomer } from "./interfaces/ICustomer";
import { DataManager } from "./Utils/DataManager";
import { InputManager } from "./Core/InputManager";
import Settings from "./components/Settings/Settings";
import { ISetting } from "./interfaces/ISetting";
import { getGroupMember } from "./api/graph";
import { DataManagerKeys } from "./Utils/DataManagerKeys";
import { IQuoteSummary } from "./interfaces/IQuoteSummery";
import LoadingContainer from "./components/molecules/LoadingContainer/LoadingContainer";
import OrderingsContainer from "./components/Ordering/Ordering";
import TimesafeContainer from "./components/Timesafe/Timesafe";

function App() {
    const { instance, accounts } = useMsal();
    const account: AccountInfo | null = useAccount(accounts[0] || {});
    const admin: boolean = isAdmin(account);
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const isAuthenticated = useIsAuthenticated();

    const getData = async () => {
        if (account) {
            await AccountData.setAccountData(instance, account);
            const group = await getGroupMember();
            const reviewers = group.value.map((employee: any) => employee.displayName);
            DataManager.SetValue(DataManagerKeys.SYSTEM_REVIEWERS, reviewers);

            const customers = await API.Customer.getAllCustomerData().catch((error: string) => {
                console.error(error);
            });

            const templates = await API.Templates.getTemplates().catch((error: string) => {
                console.error(error);
            });

            const settings = await API.Settings.getAllSettings().catch((error: string) => {
                console.error(error);
            });
            const result = await Promise.all([customers, templates, settings]);

            await result[2].forEach((setting: ISetting) => {
                setting.Data = JSON.parse(setting.Data);
                DataManager.SetValue(setting.Key, setting);
            });

            setCustomers(result[0]);

            // Store all template Data in DataManager to reach it from everywhere
            //  result[1].DictionaryStorages.forEach((template: any) =>
            //      DataManager.SetValue(template.Name, template.Value)
            //  );

            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, [account]);

    useEffect(() => {
        InputManager.Initialize();
        // getData();
    }, []);

    const HomeFrame = () => {
        return (
            <AuthenticatedTemplate>
                <HomeContainer account={account} customers={customers}></HomeContainer>
            </AuthenticatedTemplate>
        );
    };

    const SettingsFrame = () => {
        return (
            <AuthenticatedTemplate>
                <Settings></Settings>
            </AuthenticatedTemplate>
        );
    };

    const FilterFrame = () => {
        const { state } = useLocation();
        return (
            <AuthenticatedTemplate>
                <FilterContainer customers={customers} linkState={state}></FilterContainer>
            </AuthenticatedTemplate>
        );
    };

    const OfferFrame = () => {
        const { state } = useLocation();

        return (
            <AuthenticatedTemplate>
                <QuoteContainer data={state}></QuoteContainer>
            </AuthenticatedTemplate>
        );
    };

    const OfferFrameDirected = (id : string, version : string) => {
        console.log(id, version);
        const state =  {
            id : id,
            version : version
        };

        return (
            <AuthenticatedTemplate>
                <QuoteContainer data={state}></QuoteContainer>
            </AuthenticatedTemplate>
        );
    };

    const TimesafeOfferFrame = () => {
        const { state } = useLocation<IQuoteSummary>();

        return (
            <AuthenticatedTemplate>
                <TimeSafeOfferContainer quote={state}></TimeSafeOfferContainer>
            </AuthenticatedTemplate>
        );
    };

    const ThanosV1Frame = () => {
        return (
            <AuthenticatedTemplate>
                <ThanosV1Container></ThanosV1Container>
            </AuthenticatedTemplate>
        );
    };

    const OrderingFrame = () => {
        return (
            <AuthenticatedTemplate>
                <OrderingsContainer></OrderingsContainer>
            </AuthenticatedTemplate>
        );
    };

    const TimesafeFrame = () => {
        return (
            <AuthenticatedTemplate>
                <TimesafeContainer></TimesafeContainer>
            </AuthenticatedTemplate>
        );
    };

    setColor(localStorage.getItem("theme-color") || "color-blue");
    setMode(localStorage.getItem("theme-mode") || "light");

    return (
        <div className="App theme-light">
            <header className="header">
                <div className="header--text">THANOS</div>
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
                    rel="stylesheet"
                />
                <Login />
            </header>
            {!loading && isAuthenticated && account && (
                <div className="main">
                    <BrowserRouter>
                        <AuthenticatedTemplate>
                            <Navigation admin={admin}></Navigation>
                        </AuthenticatedTemplate>
                        <Route exact path="/" component={HomeFrame} />
                        <Route exact path="/filter" component={FilterFrame} />
                        <Route exact path="/offer" component={OfferFrame} />
                        <Route exact path="/timesafeoffer" component={TimesafeOfferFrame} />
                        <Route exact path="/v1thanos" component={ThanosV1Frame} />
                        <Route exact path="/settings" component={SettingsFrame} />
                        <Route exact path="/ordering" component={OrderingFrame} />
                        <Route exact path="/timesafe" component={TimesafeFrame} />

                        <Route exact path="/offer/:id/:version" 
                            render={(props) => {
                                const {id, version} = props.match.params;
                                return OfferFrameDirected(id,version);
                            }} />
                    </BrowserRouter>
                </div>
            )}
            {loading && isAuthenticated && <LoadingContainer text="Loading all Customer data from Backend" />}
            {!isAuthenticated && (
                <div className="logged-out-screen">
                    <p>WELCOME TO THANOS</p>
                    <p>PLEASE LOGIN</p>
                    <div className="login-text">Login</div>
                </div>
            )}
        </div>
    );
}

export default App;
