import DashboardContainer from "../Container";
import "./style.scss";

function ContainerOpenQuote(props: { classes: string}) {
    let url = "";

    return (
        <DashboardContainer headerName="OPEN QUOTE" className={`ds-openquote ${props.classes}`}>
            <div className="openquoteurl__input">
                <input
                    placeholder="insert quote URL"
                    className="input"
                    type="text"
                    value={url}
                    onChange={(e) => window.location.href = (e.target.value)}
                    >
                </input>
            </div>

        </DashboardContainer>
    );
}

export default ContainerOpenQuote;
