import { EMPTY_ORGANIZATION, IOrganization } from "./IOrganization";
import { EMTPY_PROJECT, IProject } from "./IProject";
import { EMPTY_QUOTE_OBJECT, IQuote } from "./IQuote";

export interface IQuoteSummary {
    OfferObject: IQuote;
    Organization: IOrganization;
    Project: IProject;
    documentState: number;
    id: string;
    info: string;
    name: string;
    type: string;
    version: string;
    tagForm: string;
}

export const EMPTY_QUOTE_SUMMARY: IQuoteSummary = {
    OfferObject: EMPTY_QUOTE_OBJECT,
    Organization: EMPTY_ORGANIZATION,
    Project: EMTPY_PROJECT,
    documentState: 0,
    id: "",
    info: "",
    name: "",
    type: "",
    version: "",
    tagForm: ""
};
