import { pdf } from "@react-pdf/renderer";
import React, { useState } from "react";
import { IQuoteSummary } from "../../interfaces/IQuoteSummery";
import PdfQuote from "../QuoteContainer/PdfQuote";
import { saveAs } from "file-saver";
import LoadingContainer from "../molecules/LoadingContainer/LoadingContainer";

function GeneratePdfForm(props: { offer: IQuoteSummary; closeModal: Function }) {
    const [generatePdf, setGeneratePdf] = useState<boolean>(false);
    const [showTasks, setShowTasks] = useState<boolean>(true);
    const [showSubtotal, setShowSubtotal] = useState<boolean>(true);
    const [showDescription, setShowDescription] = useState<boolean>(true);

    async function setStateAsync(state: boolean) {
        return new Promise((resolve: any) => {
            setGeneratePdf(state);
            resolve();
        });
    }

    const generatePdfDocument = async (offer: IQuoteSummary) => {
        await setStateAsync(true);
        const blob = await pdf(
            <PdfQuote
                offer={offer}
                showTasks={showTasks}
                showSubtotal={showSubtotal}
                showDescription={showDescription}
            />
        ).toBlob();

        saveAs(
            blob,
            `${offer.Organization.ClientNo}.${offer.Project.ProjectNo}.${offer.Project.ProjectName}_${offer.id}-${offer.version}.pdf`
        );
        setGeneratePdf(false);
    };

    return (
        <div className="form form__newQuote">
            <div className="form row title">PDF Settings</div>
            {!generatePdf && (
                <>
                    <div className="form row">
                        <div className="input">
                            <input
                                checked={showTasks}
                                type="checkbox"
                                onChange={(event) => setShowTasks(event.target.checked)}
                            />
                            <label>Show tasks</label>
                        </div>
                    </div>
                    <div className="form row">
                        <div className="input">
                            <input
                                checked={showDescription}
                                autoComplete="off"
                                type="checkbox"
                                onChange={(event) => setShowDescription(event.target.checked)}
                            />
                            <label>Show description of tasks</label>
                        </div>
                    </div>
                    <div className="form row">
                        <div className="input">
                            <input
                                checked={showSubtotal}
                                autoComplete="off"
                                type="checkbox"
                                onChange={(event) => setShowSubtotal(event.target.checked)}
                            />
                            <label>Show subtotal after every phase</label>
                        </div>
                    </div>
                    <div className="button button__add" onClick={() => generatePdfDocument(props.offer)}>
                        GENERATE PDF
                    </div>
                </>
            )}
            {generatePdf && <LoadingContainer text="Generating PDF" />}

            <div className="form-footer"></div>
        </div>
    );
}
export default GeneratePdfForm;
