import React, { useEffect, useState } from "react";
import { IProject } from "../../interfaces/IProject";
import API from "../../api/apiHandler";
import moment from "moment";
import "moment/locale/de";
import "./style.scss";
import Select from "react-select";
import { IIdString } from "../../interfaces/IIdString";
import { ITsProjectPhases } from "../../interfaces/ITsProjectPhases";
import { ICustomer } from "../../interfaces/ICustomer";
//import { getWithToken } from "../../tsapi/apiRequets";
//import { getToken } from "../../tsapi/Auth";
import { GetTsApiBarerToken } from "../../tsapi/tsapiToken";
import { TSLOGIN_PASSWORD, TSLOGIN_SECRET, TSLOGIN_USERNAME } from "../../config";

function TimesafeContainer() {
    moment.locale("de");

    const [organization, setOrganization] = useState<[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<{}>({});

    const [project, setProject] = useState<[]>([]);
    const [selectedProject, setSelectedProject] = useState<{}>({});

    const [projPhase, setProjPhase] = useState<[]>([]);
    const [selectedProjPhase, setSelectedProjPhase] = useState<{}>({});

    const [customers, setCustomers] = useState<ICustomer[]>([]);

    //const [TimeText, setTimeText] = useState<string>("");

    console.log("TEST ORDERING CONTAINER rendered");

    async function getOrganizations() {
        let organizations = await API.TimeSafeFunctions.getAllTimeSafeOrganizations();
        setOrganization(organizations);/*
        let organization : [IOrganization];
        customer.forEach(e => {
            organization.pop(e)
        });*/
    }

    async function getCustomerData(){
        let customer = await API.Customer.getAllCustomerData().catch((error: string) => {
            console.error(error);
        });
        setCustomers(customer);
    }

    async function getProjectPhases(projectId: string | "") {
        let ph = (await API.TimeSafeProjectPhases.getAllTimeSafeProjPhases(projectId)) as [ITsProjectPhases];
        let phases: [{ value: string; label: string }] = [{ value: "0", label: "" }];

        ph.forEach((p) => {
            if (p.AktivityId == 0) {
                phases.push({ value: p.PhaseTitle, label: p.PhaseTitle });
            }
        });

        setProjPhase(phases as any);
    }

    async function getProjects(orgId: string | null) {
        let proj = (await API.Projects.getProjects(null, null, orgId, null)) as [IProject];
        //let projects : [{value: string, label: string}] ;
        let projects: [{ value: string; label: string }] = [{ value: "0", label: "" }];

        proj.forEach((p) => {
            projects.push({ value: `${p.ProjectId}`, label: p.ProjectName });
        });
        setProject(projects as any);
    }

    console.log(project);

    useEffect(() => {
        getCustomerData();
        console.log(customers);
        getOrganizations();
    }, []);

    let proList = project.map((item: { value: string; label: string }) => {
        return {
            value: item.value,
            label: item.label
        };
    });

    let orgList = organization.map((item: IIdString) => {
        return {
            value: item.String,
            label: item.String
        };
    });

    let phaseList = projPhase.map((item: { value: string; label: string }) => {
        return {
            value: item.value,
            label: item.label
        };
    });

    async function handleOnChangeOrganization(event: any) {
        if (event.value === "") return;
        if (event.value === null) return;

        let orgString = event.value;
        setSelectedOrganization({ label: orgString, value: orgString });

        let organizId = 0;
        let org = (await API.TimeSafeFunctions.getAllTimeSafeOrganizations()) as [IIdString];
        org.forEach((o) => {
            if (o.String === orgString) {
                organizId = o.Id;
            }
        });
        console.log(organizId);
        getProjects(organizId.toString());
    }

    /*
    async function storeOrder() {
        let emp: IEmployee = {
            FirstName: AccountData.getUser().givenName || "",
            LastName: AccountData.getUser().surname || "",
            TimeSafeId: 0
        };

        let organiz = EMPTY_ORGANIZATION;
        let org = (await API.TimeSafeFunctions.getAllTimeSafeOrganizations()) as [IIdString];
        org.forEach((o) => {
            let selOrg = (selectedOrganization as { value: string; label: string }).label;
            if (o.String === selOrg) {
                organiz.OrganizationId = o.Id;
                organiz.Name = o.String;
            }
            console.log(o + selOrg);
        });
        console.log(org);

        let projList = (await API.Projects.getProjects(null, null, null, null)) as [
            { DateTimeEnd: string; DateTimeStart: string; Id: number; ProjectName: string ; ProjectNo: string }
        ];

        let selProject = EMTPY_PROJECT;
        projList.forEach(p => {
            let selProj = selectedProject as {value: string, label: string};

          if(p.ProjectName === selProj.label){
            selProject.ProjectId = p.Id;
            selProject.ProjectName = p.ProjectName;
            selProject.ProjectNo = p.ProjectNo;
          }
        });

        let orderLabel: IOrderLabel = {
            OrderId: orderId,
            TransportCompany: (selectedTransport as { value: string; label: string }).label,
            Currency: (selectedCurrencies as { value: string; label: string }).label,
            MaterialText: materialText,
            MaterialType: (selectedMaterialType as { value: string; label: string }).label,
            Booked: new Date(2000, 1, 1),
            Cost: Number(costAmount),
            CreationDate: new Date(),
            Booking: false,
            Employee: emp,
            DistributorOrderId: distributorOrderId,
            FileReferences: [""],
            FileStorage: "",
            CostAdd: Number(costAdd),
            Organization: organiz,
            Payed: new Date(2000, 1, 1),
            PaymentMethod: (selectedPaymentMethodes as { value: string; label: string }).label,
            Phase: (selectedProjPhase as { value: string; label: string }).label,
            Project: selProject,
            WorkGroup: ""
        };

        saveOrder(orderLabel);
        console.log(orderLabel);
        //TODO: OrderLabel
    }
*/
    async function startTimeBooking() {
        console.log("Start Time Booking");
        const token = await GetTsApiBarerToken(TSLOGIN_USERNAME,TSLOGIN_PASSWORD,TSLOGIN_SECRET);
        //TODO: secret from browser or database

        let token2: string;
         token2 = `${token}a`;
        console.log(token2);

        return token2;
       // const response = await getWithToken(token, API_ADMIN_URL + URL_Summary_, parameter) // force break
        //    .catch((error: string) => reject(error));
    }

    async function handleOnChangeProject(event: any) {
        if (event.value === "") return;
        if (event.value === null) return;
        let selProjectString = event.label;
        setSelectedProject({ label: selProjectString, value: selProjectString });

        let projList = (await API.Projects.getProjects(null, null, null, null)) as [
            { DateTimeEnd: string; DateTimeStart: string; Id: number; ProjectName: string ; ProjectNo: string }
        ];
        let projId = "";
        projList.forEach((c) => {
            if (c.ProjectName === selProjectString) {
                projId = `${c.Id}`;
            }
        });
        getProjectPhases(projId);
    }

    return (
        <div className="timesafe--container">
            <table className="tableContainter" width="900px">
                <th>
                    <div id="TimesafeBlock">
                        <label id="TimesafeBlockTitel">TIMESAFE DETAILS</label>
                        <tr>
                            <div id="TimesafeTableBox">
                                <label>ORGANIZATION:</label>
                                <Select
                                    className="select"
                                    options={orgList}
                                    value={selectedOrganization}
                                    onChange={(event: any) =>
                                        handleOnChangeOrganization({ label: event.label, value: event.value })
                                    }
                                />
                            </div>
                        </tr>

                        <tr>
                            <div id="TimesafeTableBox">
                                <label>PROJECT:</label>
                                <Select
                                    className="select"
                                    options={proList}
                                    value={selectedProject}
                                    onChange={(event: any) =>
                                        handleOnChangeProject({ label: event.label, value: event.value })
                                    }
                                />
                            </div>
                        </tr>

                        <tr>
                            <div id="TimesafeTableBox">
                                <label>PHASES:</label>
                                <Select
                                    className="select"
                                    options={phaseList}
                                    value={selectedProjPhase}
                                    onChange={(event: any) =>
                                        setSelectedProjPhase({ label: event.label, value: event.value })
                                    }
                                />
                            </div>
                        </tr>

                        <tr>
                            <button id="StartButton" onClick={startTimeBooking}>START</button>
                            
                        </tr>
                    </div>
                    

                </th>
                <th></th>
            </table>
        </div>
    );
}

export default TimesafeContainer;
