import { IQuote } from "../../interfaces/IQuote";
import { IProjectPhaseOffer, PhaseState } from "../../interfaces/IProjectPhase";
import { ITask } from "../../interfaces/ITask";
import { InputDateTodateString } from "../../Utils/Utilities";
import { IQuoteSummary } from "../../interfaces/IQuoteSummery";
import API from "../../api/apiHandler";

export async function addPhase(offer: IQuoteSummary | undefined, callbackFn: Function) {
    if (!offer) return;

    const newPhaseID = await API.ID.GetPhaseId(offer.id);

    const newPhase: IProjectPhaseOffer = {
        Id: newPhaseID.Id,
        Name: "New Phase",
        State: PhaseState.DRAFT,
        Tasks: [],
        Type: 0
    };
    const _phases: IProjectPhaseOffer[] = [...offer.OfferObject.Phases];
    _phases.push(newPhase);

    const newOffer: IQuote = {
        ...offer.OfferObject,
        Phases: _phases
    };
    const newSummary: IQuoteSummary = {
        ...offer,
        OfferObject: newOffer
    };
    callbackFn(newSummary);
}

export function deleteTask(
    offer: IQuoteSummary | undefined,
    phaseIndex: number,
    taskIndex: number,
    callbackFn: Function
) {
    if (!offer) return;
    let newOffer: IQuoteSummary = { ...offer };
    newOffer.OfferObject.Phases[phaseIndex].Tasks.splice(taskIndex, 1);
    callbackFn(newOffer);
}

export async function addTask(
    offer: IQuoteSummary | undefined,
    phaseIndex: number,
    taskType: number,
    callbackFn: Function
) {
    if (!offer?.OfferObject) return;
    const newTaskID = await API.ID.GetTaskId(offer.id);

    const newTask: ITask = {
        Id: newTaskID.Id,
        Title: "new",
        CompletitionState: 0,
        Date: InputDateTodateString(new Date().toString()),
        Description: "",
        Duration: 0,
        ReleaseState: 0,
        Workstate: 0,
        WorkerGroup: "",
        HourlyRate: 0,
        SubTasks: [],
        TaskType: taskType
    };

    const _phase: IProjectPhaseOffer = { ...offer.OfferObject.Phases[phaseIndex] };
    _phase.Tasks.push(newTask);
    const _phases: IProjectPhaseOffer[] = [...offer.OfferObject.Phases];
    _phases.splice(phaseIndex, 1, _phase);
    const newOffer: IQuote = {
        ...offer.OfferObject,
        Phases: _phases
    };

    const newSummary: IQuoteSummary = {
        ...offer,
        OfferObject: newOffer
    };
    callbackFn(newSummary);
}
