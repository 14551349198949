import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ICustomer } from "../../../interfaces/ICustomer";
import DashboardContainer from "../Container";
import "./style.scss";

function ContainerCustomers(props: { classes: string; customers: ICustomer[] }) {
    const [filter, setFilter] = useState<string>("");

    const stringFilter = (customer: ICustomer) =>
        customer.Organization.Name.toLowerCase().includes(filter.toLowerCase());

    const customerList = props.customers.filter(stringFilter).map((customer) => (
        <Link
            className={`db-customer-list-item ${filter} `}
            to={{
                pathname: "filter",
                state: {
                    filter: "customers",
                    content: customer.Organization.Name
                }
            }}>
            <div className="nav-item-text">
                <span className="text-customer-number">{customer.Organization.ClientNo}</span>
                <span>{customer.Organization.Name}</span>
            </div>
        </Link>
    ));

    return (
        <DashboardContainer headerName="CUSTOMERS" className={`ds-customer ${props.classes}`}>
            <div className="customers__input">
                <input
                    placeholder="Search for customers"
                    className="input"
                    type="text"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}></input>
            </div>

            {customerList.length === 0 ||
                (filter === "" && (
                    <div className="numOfCustomers">
                        <span className="number">{props.customers.length}</span>
                        <span className="label">Customers</span>
                    </div>
                ))}
            {customerList.length > 0 && filter !== "" && <div className="db-customer-list">{customerList}</div>}
        </DashboardContainer>
    );
}

export default ContainerCustomers;
