import React from "react";
import "./style.scss";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function LoadingContainer(props: { color?: string; text?: string }) {
    return (
        <div className="loading-container">
            <div>
                <AiOutlineLoading3Quarters className="fa-spin" size="2em" color={props.color} />
                <p>{props.text}</p>
            </div>
        </div>
    );
}
export default LoadingContainer;
