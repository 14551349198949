import React from "react";
import { IQuoteSummary } from "../../interfaces/IQuoteSummery";
import TimeSafeOfferList from "./TimeSafeOfferList";

function TimeSafeOfferContainer(props: { quote: IQuoteSummary }) {
    return (
        <div className="container">
            <TimeSafeOfferList quote={props.quote} />
        </div>
    );
}

export default TimeSafeOfferContainer;
