import React from "react";
import { ICustomer } from "../../interfaces/ICustomer";
import { FormState } from "./NewQuoteForm";
import Select from "react-select";

function Step1(props: { customers: ICustomer[]; selectedCustomer: ICustomer; setSelectedCustomer: Function }) {
    //let filterdList = filterList.map((item) => {<li onClick={(e) => selectItem(e)}>{item.Organization.Name}</li>});

    let sortedFilteredList : ICustomer[];   
    sortedFilteredList = props.customers.sort((a,b)=> a.Organization.Name.localeCompare(b.Organization.Name));

    let filterdList = sortedFilteredList.map((item) => {
        return {
            value: item.Organization.Name,
            label: item.Organization.Name
        };
    });

    function selectItem(e: any) {
        let customer = props.customers.find((customer: ICustomer) => customer.Organization.Name === e.value);
        if (customer) {
            props.setSelectedCustomer(customer);
        }
    }

    return (
        <FormState>
            <div className="form row title">Customerdetails</div>
            <div className="form row">
                <div className="input">
                    <Select
                        className="select"
                        options={filterdList}
                        onChange={(event) => selectItem(event)}
                        value={{
                            value: props.selectedCustomer.Organization.Name,
                            label: props.selectedCustomer.Organization.Name
                        }}
                    />
                    <label htmlFor="male">Customername</label>
                </div>
                <div className="input">
                    <input
                        autoComplete="off"
                        type="text"
                        name="gender"
                        value={props.selectedCustomer.Organization?.ClientNo}
                        onChange={(e) =>
                            props.setSelectedCustomer({
                                ...props.selectedCustomer,
                                Organization: { ...props.selectedCustomer.Organization, ClientNo: e.target.value }
                            })
                        }
                    />
                    <label htmlFor="male">Customernumber</label>
                </div>
            </div>
            <div className="form row">
                <div className="input">
                    <input
                        autoComplete="off"
                        type="text"
                        name="gender"
                        value={props.selectedCustomer.Organization?.Nation}
                        onChange={(e) =>
                            props.setSelectedCustomer({
                                ...props.selectedCustomer,
                                Organization: { ...props.selectedCustomer.Organization, Nation: e.target.value }
                            })
                        }
                    />
                    <label htmlFor="male">Nation</label>
                </div>
                <div className="input">
                    <input
                        autoComplete="off"
                        type="text"
                        name="gender"
                        value={props.selectedCustomer.Organization?.NationCode}
                        onChange={(e) =>
                            props.setSelectedCustomer({
                                ...props.selectedCustomer,
                                Organization: { ...props.selectedCustomer.Organization, NationCode: e.target.value }
                            })
                        }
                    />
                    <label htmlFor="male">Nationcode</label>
                </div>
            </div>
            <div className="form row">
                <div className="input">
                    <input
                        type="text"
                        name="gender"
                        value={props.selectedCustomer.Organization?.Street}
                        onChange={(e) =>
                            props.setSelectedCustomer({
                                ...props.selectedCustomer,
                                Organization: { ...props.selectedCustomer.Organization, Street: e.target.value }
                            })
                        }
                    />
                    <label htmlFor="male">Street</label>
                </div>
            </div>
            <div className="form row">
                <div className="input">
                    <input
                        type="text"
                        name="gender"
                        value={props.selectedCustomer.Organization?.ZipCode}
                        onChange={(e) =>
                            props.setSelectedCustomer({
                                ...props.selectedCustomer,
                                Organization: { ...props.selectedCustomer.Organization, ZipCode: e.target.value }
                            })
                        }
                    />
                    <label htmlFor="male">Zip Code</label>
                </div>
                <div className="input">
                    <input
                        type="text"
                        name="gender"
                        value={props.selectedCustomer.Organization?.City}
                        onChange={(e) =>
                            props.setSelectedCustomer({
                                ...props.selectedCustomer,
                                Organization: { ...props.selectedCustomer.Organization, City: e.target.value }
                            })
                        }
                    />
                    <label htmlFor="male">City</label>
                </div>
            </div>
            <div className="form row">
                <div className="input">
                    <input
                        type="text"
                        name="gender"
                        value={props.selectedCustomer.Organization?.ContactMail}
                        onChange={(e) =>
                            props.setSelectedCustomer({
                                ...props.selectedCustomer,
                                Organization: { ...props.selectedCustomer.Organization, ContactMail: e.target.value }
                            })
                        }
                    />
                    <label htmlFor="male">Mail</label>
                </div>
                <div className="input">
                    <input
                        type="text"
                        name="gender"
                        value={props.selectedCustomer.Organization?.ContactPhone}
                        onChange={(e) =>
                            props.setSelectedCustomer({
                                ...props.selectedCustomer,
                                Organization: { ...props.selectedCustomer.Organization, ContactPhone: e.target.value }
                            })
                        }
                    />
                    <label htmlFor="male">Phone</label>
                </div>
            </div>
        </FormState>
    );
}

export default Step1;
