import React, { ReactNode } from "react";
import "./style.scss";

type ButtonProps = {
    children: ReactNode;
    variant?: "primary" | "secondary" | "danger";
    onClick: Function;
};

const Button = ({ children, variant, onClick }: ButtonProps) => (
    <button className={`button ${variant}`} onClick={() => onClick()}>
        {children}
    </button>
);

export const PrimaryButton = ({ children, ...props }: ButtonProps) => (
    <Button variant="primary" {...props}>
        {children}
    </Button>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SecondaryButton = ({ children, ...props }: ButtonProps) => (
    <Button variant="secondary" {...props}>
        {children}
    </Button>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DangerButton = ({ children, ...props }: ButtonProps) => (
    <Button variant="danger" {...props}>
        {children}
    </Button>
);
