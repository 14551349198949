import React from "react";

import { IProjectPhaseOffer, IProjectPhaseTimeSafeOffer } from "../../interfaces/IProjectPhase";
import Entry from "./Entry";

function Phase(props: { timesafephase: IProjectPhaseTimeSafeOffer; index: number; phase: IProjectPhaseOffer }) {
    let taskList = props.timesafephase.Tasks.map((entryTimeSafe: any, index: number) => (
        <Entry entryTimeSafe={entryTimeSafe} index={index} entry={props.phase.Tasks[index]}></Entry>
    ));

    return (
        <div key={props.index}>
            <div className="timesafeoffer--phaseTitle">{props.timesafephase.Name}</div>
            <div className="timesafeoffer-phase--task">
                <div className="task--tag task--header ">TASK ID</div>
                <div className="task--title task--header">Description</div>
                <div className="task--target task--header">Offered [h]</div>
                <div className="task--actual task--header">Entered [h]</div>
                <div className="task--actual task--header">Difference [h]</div>
                <div className="task--braked"></div>
                <div className="task--progress task--header">Compl. [%]</div>
                <div className="task--forecast task--header">Estimated [h]</div>
                <div className="task--forecast-percent task--header">[%]</div>
                <div className="task--open task--header">open [h]</div>
            </div>
            {taskList}
        </div>
    );
}

export default Phase;
