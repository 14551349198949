import { IFilter } from "../../../interfaces/IFilter";
import { IFilterGroup } from "../../../interfaces/IFilterGroup";

const GROUP_BY_CUSTOMER: IFilter = {
    id: "GROUP_BY_CUSTOMER",
    title: "Customer",
    type: "GROUPBY",
    fn: (): string => {
        return "customers";
    }
};

const GROUP_BY_PROJECTS: IFilter = {
    id: "GROUP_BY_PROJECT",
    title: "Project",
    type: "GROUPBY",
    fn: (): string => {
        return "projects";
    }
};

const GROUP_BY_QUOTE: IFilter = {
    id: "GROUP_BY_QUOTE",
    title: "Quote",
    type: "GROUPBY",
    fn: (): string => {
        return "quotes";
    }
};

export const FILTER_GROUP_BY: IFilterGroup = {
    filter: [GROUP_BY_QUOTE, GROUP_BY_CUSTOMER, GROUP_BY_PROJECTS],
    conjunction: "AND",
    type: "GROUPBY",
    name: "Group By"
};
