import React, { useState } from "react";
import "./settings.scss";
import { setColor, themeColors } from "../../Utils/theme/handleTheme";
import SettingItem from "./SettingItem";

import { DataManager } from "../../Utils/DataManager";

function Settings() {
    const [selectedThemeColor, setSelectedThemeColor] = useState<string>(localStorage.getItem("theme-color") || "");
    const [timesafeUser, setTimesafeUser] = useState<string>(localStorage.getItem("timesafe-user") || "");
    const [timesafePw, setTimesafePw] = useState<string>(localStorage.getItem("timesafe-pw") || "");

    /*
    const newSettingString = {
        Key: "KEY",
        Name: "SETTING",
        Type: "TEST",
        Data: JSON.parse("[]")
    };
*/
    //const [newSetting, setNewSetting] = useState<string>(JSON.stringify(newSettingString, undefined, 4));

    function changeColor(_color: string) {
        setSelectedThemeColor(_color);
        localStorage.setItem("theme-color", _color);
        setColor(_color);
    }

    const saveSettings = async () => {
        localStorage.setItem("timesafe-user", timesafeUser);
        localStorage.setItem("timesafe-pw", timesafePw);
    };

    /*
    function handleNewSetting() {
        const _newSetting = JSON.parse(newSetting);
        _newSetting.Data = JSON.stringify(_newSetting.Data);
        API.Settings.settSetting(_newSetting.Key, _newSetting);
    }
    */

    function handleThemeColorChange(event: React.FormEvent<HTMLSelectElement>) {
        changeColor(event.currentTarget.value);
    }

    let themeColorList = themeColors.map((theme) => (
        <option key={theme.key} value={theme.key}>
            {theme.color}
        </option>
    ));

    const settingsObject = DataManager.GetAllEntries();
    let SettingList = Object.keys(settingsObject).map((key) => (
        <SettingItem key={settingsObject[key].Key} setting={settingsObject[key]}></SettingItem>
    ));

    

    SettingList = [];
    return (
        <div className="list settings">
            <div className="list-item">
                <div className="item-title">THEME COLOR</div>
                <select
                    className="item-content"
                    onChange={(event) => handleThemeColorChange(event)}
                    value={selectedThemeColor}>
                    {themeColorList}
                </select>
            </div>

        

            <div className="input">
                <div className="item-title">Timesafe Name</div>
                <input
                        autoComplete="off"
                        type="text"
                        name="Timesafe User"
                        value={timesafeUser}
                        onChange={(e) =>
                            setTimesafeUser(e.target.value)
                        }
                    />
            </div>

            <div className="input">
                <div className="item-title">Timesafe Password</div>
                <input
                        autoComplete="off"
                        type="text"
                        name="Timesafe password"
                        value={timesafePw}
                        onChange={(e) =>
                            setTimesafePw(e.target.value)
                        }
                    />
            </div>

            <div className="button button__save" onClick={() => saveSettings()}>
                        <button>save</button>
            </div>

            {SettingList}
            {/*
            <div className="list-item">
                <div className="item-title">NEW SETTING</div>

                <textarea
                    className="item-content"
                    value={newSetting}
                    onChange={(e) => setNewSetting(e.target.value)}></textarea>
                <button onClick={() => handleNewSetting()}>SAVE</button>
            </div>
            */}
        </div>
    );
}
export default Settings;
