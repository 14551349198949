import React from "react";
import { IFilter } from "../../interfaces/IFilter";
import { IFilterGroup } from "../../interfaces/IFilterGroup";
import { IFilterMainGroup } from "../../interfaces/IFilterMainGroup";
import Filter from "./Filter";
import { FILTER_GROUP_CUSTOMER, FILTER_GROUP_EMPTY } from "./Filters/CustomerFilter";
import { FILTER_GROUP_QUOTE } from "./Filters/QuoteFilter";

function FilterNavigation(props: {
    addFilterFn: Function;
    selectedFilters: Map<string, IFilter>;
    filter: string;
    showAddQuoteForm: Function;
}) {
    let filterMainGroup: IFilterMainGroup;

    switch (props.filter) {
        case "quotes":
            filterMainGroup = FILTER_GROUP_QUOTE;
            break;
        case "customers":
            filterMainGroup = FILTER_GROUP_CUSTOMER;
            break;
        default:
            filterMainGroup = FILTER_GROUP_EMPTY;
    }

    let FilterGroups = filterMainGroup.filterGroups.map((group: IFilterGroup) => {
        let tempFilters = group.filter.filter(
            (filter: IFilter) => !props.selectedFilters.has(filter.id) || filter.type === "GROUPBY"
        );

        let filters = tempFilters.map((filter: IFilter) => {
            if (filter.type === "GROUPBY" && filter.fn() === props.filter) {
                return <Filter active={true} filter={filter} addFilterFn={props.addFilterFn} key={filter.id}></Filter>;
            } else {
                return <Filter active={false} filter={filter} addFilterFn={props.addFilterFn} key={filter.id}></Filter>;
            }
        });

        return (
            <div className="navigation__group" key={group.name}>
                <div className="group__title">{group.name}</div>
                <div className="group__filters">{filters}</div>
            </div>
        );
    });

    return (
        <div className="filter-main__navigation">
            <div className="navigation__add-Quote-btn" onClick={() => props.showAddQuoteForm()}>
                + Add Quote{" "}
            </div>
            {FilterGroups}
        </div>
    );
}

export default FilterNavigation;
