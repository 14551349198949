import { IPublicClientApplication } from "@azure/msal-browser";
import { AccountInfo } from "@azure/msal-common";
import { API_ADMIN_URL } from "../config";
import { getToken } from "../Utils/Auth";
import { getWithToken } from "./apiRequets";

const URL_Entry = "EntryFromProject";

export async function getProjectEntrys(
    instance: IPublicClientApplication,
    account: AccountInfo,
    from: string | null,
    to: string | null,
    id: number
): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        const token = await getToken();
        let parameter = {};
        if (from !== null && to !== null) {
            parameter = { dtfrom: from, dtto: to, projectid: id };
        }

        const response = await getWithToken(token, API_ADMIN_URL + URL_Entry, parameter) // force break
            .catch((error: string) => reject(error));

        if (response.ok) {
            let body = await response.json();
            resolve(body);
        } else {
            reject(response.statusText);
        }
    });
}

export default { getProjectEntrys };
