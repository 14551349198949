import { EMPTY_EMPLOYEE, IEmployee } from "./IEmployee";

export interface IHistoryInfo {
    Id: number;
    Employee: IEmployee;
    DateTime: string;
    HistoryVersion: number;
}

//TODO: romand, entweder default date today, oder kein absturz wenn leer :-)
export const EMPTY_HISTORY_INFO: IHistoryInfo = { Id: 0, Employee: EMPTY_EMPLOYEE, DateTime: "01.01.2021, 12:00:00", HistoryVersion: 0 };
