import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Message } from "../../../Core/Message";
import { EventMessage } from "../../../Core/Messages";
import { IProjectPhaseOffer } from "../../../interfaces/IProjectPhase";
import { IWorkGroup } from "../../../interfaces/IWorkGroup";
import Phase from "../Phase/Phase";

function QuoteEditDocumentBody(props: {
    switchEnabled: boolean;
    onDragEnd: Function;
    phases: IProjectPhaseOffer[];
    quoteId: string;
    workGroups: IWorkGroup[];
    quoteEditable: boolean;
}) {
    let phaselist = props.phases.map((phase: IProjectPhaseOffer, index: number) => (
        <Phase
            index={index}
            switchEnabled={props.switchEnabled}
            phase={phase}
            key={phase.Id}
            quoteId={props.quoteId}
            workgroups={props.workGroups}
            quoteEditable={props.quoteEditable}></Phase>
    ));

    const onMouseDown = (result: any) => {
        let el: HTMLElement = result.target;
        if (!el) return;
        if (el.tagName !== "svg" && el.tagName !== "path") return;

        let svg;
        if (el.tagName === "path") {
            svg = el.parentElement;
        } else {
            svg = el;
        }
        let div = svg?.parentElement?.parentElement?.parentElement;
        if (div?.classList.contains("offerlist--phase")) {
            let el = document.getElementsByClassName("offerlist--phase");
            Array.from(el).forEach((els) => els.classList.add("offerlist--phase-minimized"));
        }
    };

    return (
        <DragDropContext onDragEnd={(event) => props.onDragEnd(event)}>
            <Droppable droppableId="phase-container" type="phase">
                {(provided) => (
                    <div className="offerlist--phase-container" ref={provided.innerRef} onMouseDown={onMouseDown}>
                        {phaselist}
                        {provided.placeholder}
                        {props.quoteEditable && (
                            <button
                                className="add-phase__button"
                                onClick={() => Message.Send(EventMessage.ADD_PHASE, undefined)}>
                                Add Phase
                            </button>
                        )}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default QuoteEditDocumentBody;
