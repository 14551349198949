import { EMPTY_HISTORY_INFO, IHistoryInfo } from "./IHistoryInfo";
import { IProjectPhaseOffer } from "./IProjectPhase";

export interface IQuote {
    Phases: IProjectPhaseOffer[];
    InvoicingType: number;
    Created: IHistoryInfo;
    Modified: IHistoryInfo[];
    Reviewed: IHistoryInfo[];
}

export enum QuoteState {
    OPEN = 0,
    READY = 1,
    SENT = 2,
    WORK = 3,
    DONE = 4,
    REJECTED = 5,
    ARCHIVED = 6
}

export let EMPTY_QUOTE_OBJECT: IQuote = {
    Phases: [],
    InvoicingType: 0,
    Created: EMPTY_HISTORY_INFO,
    Modified: [],
    Reviewed: []
};
