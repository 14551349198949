export enum DataManagerKeys {
    //Template Data
    TEMPLATE_INVOICE_TYPES = "TEMPLATE_INVOICE_TYPES",
    TEMPLATE_OFFER_STATES = "TEMPLATE_OFFER_STATES",

    //USER Data

    //System DAta
    SYSTEM_REVIEWERS = "SYSTEM_REVIEWERS"
}
