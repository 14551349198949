import React, { useState } from "react";
import { ISetting } from "../../interfaces/ISetting";

function SettingItem(props: { setting: ISetting }) {
    const [data, setData] = useState<ISetting>(props.setting);

    const handleDataChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setData(JSON.parse(event.target.value));
    };

    return (
        <div className="list-item">
            <div className="item-title">{data.Key}</div>

            <textarea
                onChange={(event) => handleDataChange(event)}
                className="item-content"
                value={JSON.stringify(data, undefined, 4)}></textarea>
        </div>
    );
}
export default SettingItem;
